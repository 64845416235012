import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['rejectModalDocumentErrorRadioButton', 'rejectModalDocumentTypeRadioButton', 'textArea'];

  connect() {
    this.rejectModal = document.getElementById('admin-reject-reasons-modal');
    this.boundDismissRejectModal = this.dismissRejectModal.bind(this);
    this.onModalDismissDo(this.rejectModal, this.boundDismissRejectModal);
  }

  disconnect() {
    this.onModalDismissDo(this.rejectModal, this.boundDismissRejectModal, false);
  }

  toggleTextArea(event) {
    if (event.target.value === 'custom') {
      this.textAreaTarget.disabled = false;
    } else {
      this.textAreaTarget.disabled = true;
      this.resetTextArea();
    }
  }

  dismissRejectModal(_event) {
    this.resetTextArea();
    this.rejectModalDocumentTypeRadioButtonTargets.forEach(radio => radio.checked = false);
    this.rejectModalDocumentErrorRadioButtonTargets.forEach(radio => radio.checked = false);
  }

  resetTextArea() {
    this.textAreaTarget.disabled = true;
    this.textAreaTarget.value = '';
  }
};
