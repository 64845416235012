import I18n from 'i18n-js';
import BaseController from '../base_controller';
import fetchJsonWithCSRF from '../../utils/fetcher';

export default class extends BaseController {
  static targets = ['modal']
  static values = {
    communityName: String
  }

  showModal() {
    $(this.modalTarget).modal()
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }

  async shareInvitation(event) {
    event.preventDefault();

    try {
      const propertyUsersShareInvitationPath = event.target.href
      const { link } = await fetchJsonWithCSRF.get(propertyUsersShareInvitationPath);

      const message = I18n.t('views.property_users.share_invitation_modal.invitation_message', {
        communityName: this.communityNameValue,
        registrationLink: link
      })

      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank')
    } catch ({ response }) {
      triggerAlert(JSON.parse(response).message);
    } finally {
      this.closeModal()
    }
  }
}
