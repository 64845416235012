import BaseController from '../base_controller'
import I18n from 'i18n-js'
import { maskRutJquery, unMaskJquery } from '../../utilities/shared_methods';

export default class extends BaseController {
  static targets = [
    'advancedOptionsButton',
    'companionList',
    'requirable',
    'hostUserSelector',
    'propertySelector',
    'submitButton',
    'nameFormInput',
    'rutFormInput',
    'rutFormField',
    'phoneFormField',
    'estimatedLeavingDateField',
    'formBasicOptions',
    'formAdvancedOptions',
    'formPicture',
    'guestTypeRadio',
  ]
  static values = {
    companionGuestCount: Number,
    personalIdentificationType: String,
    requireIdentification: Boolean,
  }

  connect() {
    this._styleRutField()
    this.handleDisabledSubmitButton()
  }

  _styleRutField() {
    let rutFormField = this.rutFormFieldTarget
    let requireIdentification = this.requireIdentificationValue


    this.formBasicOptionsTarget.classList.toggle('quadruple', requireIdentification)
    this.formBasicOptionsTarget.classList.toggle('triple', !requireIdentification)

    if (!requireIdentification) {
      rutFormField.querySelector('.label-required').classList.add('hidden')
      rutFormField.querySelector('input').removeAttribute('required')
      rutFormField.querySelector('input').removeAttribute('oninvalid')
      this.formAdvancedOptionsTarget.prepend(rutFormField)
      this.estimatedLeavingDateFieldTarget.insertAdjacentElement('afterend', this.phoneFormFieldTarget)
    }
  }

  changePropertySelector (event) {
    let guestType = event.currentTarget.value
    let propertySelector = this.propertySelectorTarget.parentNode

    if (guestType === 'provider') {
      this.prependNewOption(propertySelector, I18n.t('views.commons.select.whole_community'), '')
    } else {
      propertySelector?.querySelector(`.cf-selector-option[data-name='${I18n.t('views.commons.select.whole_community')}']`)?.remove()
    }
  }

  getHostUserOptions(event) {
    let hostUserSelector = this.hostUserSelectorTarget
    let propertyId = event.currentTarget.value

    hostUserSelector.value = ''
    this.handleDisabledSubmitButton()

    if (!propertyId) {
      this.disableHostUserSelector()
      return
    }

    let url = '/visitas/host_user_options.json'
    let data = new URLSearchParams({ property_id: propertyId })
    fetch(`${url}?${data}`)
      .then(response => response.json())
      .then(data => this.changeHostUserSelector(data.users))
  }

  changeHostUserSelector(hostUsers) {
    let selector = this.hostUserSelectorTarget.parentNode
    let selectorPlaceholder = selector.querySelector('.selected-value')
    let labelSelector = document.getElementById('label-host_user_id')
    let hostUserBtn = document.getElementById('guest_registry_host_user_id-button')

    this.clearOptions(selector)

    let hasUsers = hostUsers.length > 0
    hostUserBtn.disabled = !hasUsers
    labelSelector.classList.toggle('hidden', !hasUsers)

    if (hasUsers) {
      selectorPlaceholder.innerHTML = I18n.t('views.commons.select.default')
      this.appendNewOption(selector, I18n.t('views.guest_registry.form.all_host_users'), 'public')
      hostUsers.forEach(hostUser => this.appendNewOption(selector, hostUser.name, hostUser.id))
    } else {
      selectorPlaceholder.innerHTML = I18n.t('views.guest_registry.form.no_host_users')
    }
  }

  disableHostUserSelector() {
    let selector = this.hostUserSelectorTarget.parentNode
    let selectorPlaceholder = selector.querySelector('.selected-value')
    let hostUserBtn = document.getElementById('guest_registry_host_user_id-button')
    let labelSelector = document.getElementById('label-host_user_id')

    this.clearOptions(selector)
    selectorPlaceholder.innerHTML = I18n.t('views.commons.select.default')
    hostUserBtn.disabled = true
    labelSelector.classList.add('hidden')
  }

  changeRegisteredAt(event) {
    let registeredAt = event.currentTarget.value
    let timeCurrent = new Date()
    let registeredAtParsed = new Date(registeredAt)

    if (registeredAtParsed > timeCurrent) {
      this.submitButtonTarget.value = I18n.t('views.guest_registry.form.save_guest_registry')
    } else {
      this.submitButtonTarget.value = I18n.t('views.guest_registry.form.save_attended')
    }
  }

  changePersonalIdentificationType(event) {
    let hasForeignIdentification = event.currentTarget.checked
    let personalIdentificationType = hasForeignIdentification ? I18n.t('views.guest_registry.form.foreign_identification') : this.personalIdentificationTypeValue
    let label = this.rutFormFieldTarget.querySelector('label')
    let labelRequired = this.rutFormFieldTarget.querySelector('.label-required').cloneNode(true)
    let rutInput = document.getElementById("guest_registry_rut");

    if (hasForeignIdentification) {
      rutInput.classList.remove("rut-field");
      unMaskJquery("#guest_registry_rut"); // use jquery util to unmask
      rutInput.setAttribute("placeholder", personalIdentificationType);
    } else {
      rutInput.classList.add("rut-field");
      maskRutJquery(); // use jquery util to mask again
    }

    label.innerHTML = personalIdentificationType + ' '
    label.appendChild(labelRequired)
  }

  toggleHideable() {
    let hidden = this.formAdvancedOptionsTarget.classList.contains('hidden')
    let modalCompleteInformation = document.getElementById('modal_complete_information')
    let isModalCompleteInformationOpen = modalCompleteInformation?.children.length > 0

    this.formAdvancedOptionsTarget.classList.toggle('hidden', !hidden);
    this.formPictureTarget.classList.toggle('hidden', !hidden);

    if (!isModalCompleteInformationOpen) {
      if (hidden) {
        this.advancedOptionsButtonTarget.innerHTML = I18n.t('views.guest_registry.form.show_less')
      } else {
        this.advancedOptionsButtonTarget.innerHTML = I18n.t('views.guest_registry.form.show_more')
      }
    }
  }

  toggleRequirable(hidden) {
    this.requirableTargets.forEach(field => {
      hidden ? field.required = true : field.required = false;
    })
  }

  maskRut() {
    maskRutJquery()
  }

  hideCompleteInformationModal(){
    $('#complete_information').modal('hide');
  }

  addCompanion() {
    const count = this.companionGuestCountValue
    this.companionGuestCountValue += 1
    let formField = document.createElement('div')
    formField.classList.add('form-field', 'two-inputs-and-button')
    let nameField = document.createElement('input')
    nameField.type = 'text'
    nameField.classList.add('form-control', 'input-sm')
    nameField.name = `companion_guest[${count}][name]`
    nameField.id = `companion_guest_${count}_name`
    nameField.placeholder = I18n.t('activerecord.attributes.guest_registry.name')
    formField.appendChild(nameField)
    let identificationField = document.createElement('input')
    identificationField.type = 'text'
    identificationField.classList.add('form-control', 'input-sm')
    identificationField.name = `companion_guest[${count}][cid]`
    identificationField.id = `companion_guest_${count}_cid`
    identificationField.placeholder = this.personalIdentificationTypeValue
    formField.appendChild(identificationField)
    let button = document.createElement('div')
    button.classList.add('btn', 'btn-default', 'btn-sm')
    let icon = document.createElement('i')
    icon.classList.add('fa', 'fa-trash-o')
    button.appendChild(icon)
    button.innerHTML += I18n.t('common.delete')
    button.setAttribute('data-action', 'click->guest-registry--form#deleteRow')
    formField.appendChild(button)
    this.companionListTarget.appendChild(formField)
  }

  deleteRow(event) {
    event.currentTarget.closest('.form-field').remove()
  }

  cancelButton() {
    window.location.reload();
  }

  handleDisabledSubmitButton() {
    let submitButton = this.submitButtonTarget,
      nameFormInput = this.nameFormInputTarget,
      propertySelector = this.propertySelectorTarget,
      hostUserSelector = this.hostUserSelectorTarget,
      rutFormInput = this.rutFormInputTarget,
      requireIdentification = this.requireIdentificationValue,
      guestTypeRadio = this.guestTypeRadioTargets.find(radio => radio.checked)

    const isNameValid = nameFormInput.value !== '',
      isPropertySelected = propertySelector.value !== '',
      isHostUserSelected = hostUserSelector.value !== '',
      isRutValid = requireIdentification ? rutFormInput.value !== '' : true,
      isGuestTypeProvider = guestTypeRadio.value === 'provider'

    const basicValidation = isNameValid &&
      (isGuestTypeProvider ? true : isPropertySelected) &&
      (isGuestTypeProvider && !isPropertySelected ? true : isHostUserSelected) &&
      isRutValid;

    if (!basicValidation) {
      submitButton.classList.add('disabled')
      return
    }

    submitButton.classList.remove('disabled')
  }
}
