import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['month', 'year', 'warning', 'input',  'submitButton']
  changed_button = false;
  warning_on = false;

  checkMinDate(event){
    let minMonth = event.currentTarget.dataset.minMonth
    let minYear = event.currentTarget.dataset.minYear
    let minDate = new Date(minYear, minMonth, 1)
    let date = new Date(this.yearTarget.value, this.monthTarget.value)

    if (date < minDate){
      if (!this.warning_on){
        this.addWarning()
        this.warning_on = true;
      }
    } else {
      if (this.warning_on){
        this.removeWarning()
        this.warning_on = false;
      }
    }
  }

  removeWarning(){
    this.warningTarget.style.display = 'none';
    this.inputTarget.classList.replace('field-with-warning', 'field')
    this.inputTarget.querySelectorAll('button').forEach((element) => {
      element.classList.remove('invalid-input')
    });
    if (this.changed_button){
        this.submitButtonTarget.disabled = false;
        this.submitButtonTarget.classList.remove('disabled');  }
  }

  addWarning(){
    this.warningTarget.style.display = 'block';
    this.inputTarget.classList.replace('field', 'field-with-warning')
    this.inputTarget.querySelectorAll('button').forEach((element) => {
      element.classList.add('invalid-input')
    });

    if (this.submitButtonTarget.disabled != true){
        this.changed_button = true;
        this.submitButtonTarget.disabled = true;
        this.submitButtonTarget.classList.add('disabled')
    }
  }
}
