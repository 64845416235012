import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['maxReached', 'textArea']
  static values = { maxChars: Number }

  connect() {
    this.checkMaxChars();
  }

  checkMaxChars() {
    if (this.textAreaTarget.value.length >= this.maxCharsValue) {
      this.maxReachedTarget.classList.remove('hidden')
      this.textAreaTarget.value = this.textAreaTarget.value.substring(0, this.maxCharsValue);
    } else {
      this.maxReachedTarget.classList.add('hidden')
    }
  }
}
