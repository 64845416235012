import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['notificationModal', 'usersBlock', 'checkbox', 'submitButton', 'column', 'dropdown']

  connect() {
    this.observer = new MutationObserver(this.changeZIndex.bind(this));
    this.observeDropdown();
  }

  displayModal(event) {
    event.preventDefault()
    let frame = document.createElement('turbo-frame')
    frame.setAttribute('id', 'users-block')
    let params = new URLSearchParams({ property_id: event.currentTarget.dataset.propertyId })
    let url = `/violations/${event.currentTarget.dataset.violationId}/list_property_users`
    let modal = document.getElementById('notify_modal')
    let usersBlock = modal.querySelector('#usersBlock')
    frame.src = `${url}?${params}`
    usersBlock.innerHTML = ''
    usersBlock.appendChild(frame)
    $(modal).modal()
  }

  notifyViolation(event) {
    event.currentTarget.disabled = true;
    document.getElementById('notify-violation-form').submit()
  }

  enableSubmitButton() {
    const checked = Array.from(this.checkboxTargets).some(target => target.checked)
    this.submitButtonTarget.disabled = !checked
  }

  observeDropdown() {
    if (this.hasDropdownTarget) {
      this.observer.observe(this.dropdownTarget, { attributes: true, attributeFilter: ['class'] });
    }
  }

  changeZIndex() {
    document.querySelectorAll('.fix-column.actions').forEach(col => {
      const dropdown = col.querySelector('.dropdown');
      if (!dropdown || !dropdown.classList.contains('open')) {
        col.style.zIndex = '1';
      }
    });

    if (this.dropdownTarget.classList.contains('open')) {
      this.columnTarget.style.zIndex = '2';
    }
  }

  disconnect() {
    this.observer.disconnect();
  }
}
