import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkboxes', 'checkAll']

  static values = {
    communityId: String,
    localStorageKey: String,
    actionButtonId: Array,
    tooltips: Object,
    submitButtonId: Array,
    blockDisabled: Boolean,
    hideCounterOnZero: Boolean,
    isNotifying: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.checkIdsSavedInLocalStorage()
    this.updateUrl()
    this.checkIfIsNotifying()
  }

  checkIfIsNotifying() {
    if (this.isNotifyingValue) {
      this.checkboxesTargets.forEach((checkbox) =>
        checkbox.disabled = true
      )

      this.checkAllTarget.disabled = true
      this.actionButtonIdValue.forEach((buttonId) =>
        document.getElementById(buttonId).disabled = true
      )
    }
  }

  check(event) {
    const id = event.target.dataset.id

    this.saveInLocalStorage(id, false)
    this.checkIfAtLeastOneIsChecked()
    this.updateUrl()
  }

  toggleDisabled(value) {
    this.actionButtonIdValue.forEach((buttonId) =>
      document.getElementById(buttonId).disabled = value
    )
  }

  toggleCheckAll(event) {
    let checked = event.target.checked
    let checkboxes = this.checkboxesTargets.filter(item => item.checked != checked)

    checkboxes.forEach((checkbox) =>
      this.handleCheckbox(checkbox, checked)
    );

    this.checkIfAtLeastOneIsChecked()
  }

  checkIfAtLeastOneIsChecked() {
    let checkboxes = this.checkboxesTargets
    let checkedOne = Array.prototype.slice.call(checkboxes).some(x => x.checked);
    let checkedInLocalStorage = localStorage.getItem(this.localStorageKeyValue)

    if (checkedInLocalStorage != null && checkedInLocalStorage != '') {
      checkedOne = true;
    }

    if (!this.blockDisabledValue) this.toggleDisabled(!checkedOne)

    if (!checkedOne && (checkedInLocalStorage == null || checkedInLocalStorage == '')) {
      localStorage.removeItem(this.localStorageKeyValue)
    }

    this.checkIfAllChecksAreChecked()
    this.updateCounter()
  }

  saveInLocalStorage(id, fromChekAll) {
    let elementsInLocalStorage = localStorage.getItem(this.localStorageKeyValue)?.split(',') || []

    if (id && !elementsInLocalStorage.includes(`${id}`)) {
      elementsInLocalStorage.push(id);
      localStorage.setItem(this.localStorageKeyValue, elementsInLocalStorage);
    } else {
      elementsInLocalStorage = elementsInLocalStorage.filter(item => item !== `${id}`)
      localStorage.setItem(this.localStorageKeyValue, elementsInLocalStorage);
    }
  }

  handleCheckbox(checkbox, checked) {
    const id = checkbox.dataset.id
    this.saveInLocalStorage(`${id}`, true)
    checkbox.checked = checked
  }

  checkIdsSavedInLocalStorage() {
    let stored_ids = localStorage.getItem(this.localStorageKeyValue)?.split(',') || []
    let checkboxes = this.checkboxesTargets

    checkboxes.forEach((checkbox) =>
      checkbox.checked = stored_ids.includes(`${checkbox.dataset.id}`)
    );

    this.checkIfAllChecksAreChecked()
    this.checkIfAtLeastOneIsChecked()
  }

  checkIfAllChecksAreChecked() {
    let checkboxes = this.checkboxesTargets
    let allChecksAreChecked = Array.prototype.slice.call(checkboxes).every(x => x.checked);

    if (checkboxes.length == 0) {
      this.checkAllTarget.checked = false
      this.checkAllTarget.disabled = true
      return
    }

    if (this.hasCheckAllTarget) {
      this.checkAllTarget.checked = allChecksAreChecked
    }
  }

  updateCounter() {
    let elementsInLocalStorage = localStorage.getItem(this.localStorageKeyValue)?.split(',') || []
    let counterElements = document.getElementsByClassName('counter')

    Array.from(counterElements).forEach((element) => {
      element.innerHTML = elementsInLocalStorage.length
      if (this.hideCounterOnZeroValue) {
        if (elementsInLocalStorage.length == 0) element.classList.add('hidden')
        else element.classList.remove('hidden')
      }
    });

    if (this.hideCounterOnZeroValue) this.modifyModal(elementsInLocalStorage.length)

    this.updateUrl()
  }

  modifyModal(selectedElements) {
    this.actionButtonIdValue.forEach((buttonId) => {
      let button = document.getElementById(buttonId)
      let tooltips = this.tooltipsValue

      if (selectedElements == 0) {
        button.classList.remove('btn-success-cf')
        button.classList.add('btn-default')
        button.parentElement.setAttribute('data-original-title', tooltips[buttonId]['all'])
      } else {
        button.classList.remove('btn-default')
        button.classList.add('btn-success-cf')
        button.parentElement.setAttribute('data-original-title', tooltips[buttonId]['selected'])
      }
    })

    if (selectedElements == 0) {
      Array.from(document.getElementsByClassName('all')).forEach((section) => section.classList.remove('hidden'))
      Array.from(document.getElementsByClassName('options_selected')).forEach((section) => section.classList.add('hidden'))
    } else {
      Array.from(document.getElementsByClassName('all')).forEach((section) => section.classList.add('hidden'))
      Array.from(document.getElementsByClassName('options_selected')).forEach((section) => section.classList.remove('hidden'))
    }
  }

  openModal() {
    $('#confirmation-modal').modal('show')
  }

  updateUrl() {
    let elementsInLocalStorage = localStorage.getItem(this.localStorageKeyValue)?.split(',') || []

    this.submitButtonIdValue.forEach((id) => {
      let button = document.getElementById(id)
      let url = button.href.split('?')[0]
      let otherParams = button.href.split('?')[1]

      url += '?ids=' + elementsInLocalStorage

      if (otherParams) {
        let otherParamsArray = otherParams.split('&')
        if (otherParamsArray[0].split('=')[0] == 'ids') otherParamsArray.shift();
        if (otherParamsArray) otherParamsArray.forEach((param) => url += '&' + param)
      }

      button.href = url
    }
    )
  }

  afterSubmit(event) {
    $(event.currentTarget.getAttribute('data-modal-id')).modal('hide')
    localStorage.removeItem(this.localStorageKeyValue)
    $('[data-toggle="tooltip"]').tooltip('destroy')

    this.actionButtonIdValue.forEach((buttonId) =>
      document.getElementById(buttonId).disabled = true
    )

    let counterElements = document.getElementsByClassName('counter')
    Array.from(counterElements).forEach((element) => {
      element.innerHTML = 0
      if (this.hideCounterOnZeroValue) {
        element.classList.add('hidden')
      }
    });

    $('input[type=checkbox]').each(function() {
      $(this)[0].checked = false;
      $(this)[0].disabled = true;
    })

    if (event.currentTarget.getAttribute('data-notice')) triggerNotice(event.currentTarget.getAttribute('data-notice'))
  }
}
