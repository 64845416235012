import BaseController from './base_controller'
import I18n from "i18n-js";

export default class extends BaseController {
  static values = { subcategories: Object, subcategoryName: String, communityId: Number }
  static targets = ['serviceBillingForm', 'warningUniqueRutModal', 'addSupplier', 'categorySuggestion', 'suggestions', 'suggestBtn', 'inputDescription', 'suggestBtnText']

  connect () {
    const categorySelect = document.getElementById('category_name-hidden-value');
    const subcategorySelect = document.getElementById('subcategory_name-hidden-value')
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    if (subcategorySelect) {
      categoryNameInput.value = categorySelect.value
      subcategoryNameInput.value = subcategorySelect.value
    }
    this.warningUniqueRutModalTarget.removeAttribute('aria-hidden');
  }

  showFormCategory (selector) {
    const category = selector.target.value
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
    } else {
      categoryForm.classList.add('hide')
    }
  }

  selectCategory (event) {
    const category = event.target?.value
    const subCategorySelect = document.getElementById('subcategory_name-hidden-value');
    const subCategorySelector = document.getElementById('subcategory_name-selector');
    const subcategoryNameButton = document.getElementById('subcategory_name-button');
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    subCategorySelect.removeAttribute('value')
    this.clearOptions(subCategorySelector)

    subCategorySelect.parentElement.getElementsByClassName('selected-value')[0].textContent = I18n.t('views.service_billings.form.none_selected')
    const categoryForm = document.getElementById('new_category');

    if (category === '0') {
      categoryForm.classList.remove('hide')
      subcategoryNameButton.setAttribute('disabled', true)
      this.resetInput();
      categoryNameInput.classList.remove('disabled')
    } else {
      subcategoryNameButton.removeAttribute('disabled')
      categoryNameInput.value = category
      subcategoryNameInput.value = ''
      categoryForm.classList.add('hide')
      this.appendOptions(category, subCategorySelector)
    }
  }

  appendOptions (category, selector) {
    const subCategoriesOptions = this.subcategoriesValue[category];

    if (subCategoriesOptions) {
      for (let subcategory of subCategoriesOptions) {
        this.appendNewOption(selector, subcategory, subcategory)
      }
    }

    this.appendNewOption(selector, I18n.t('views.service_billings.form.other_category'), 0)
  }

  toggleSupplier(event) {
    if (event.target.value === '0') {
      this.addSupplierTarget.classList.remove('hide');
    } else {
      this.addSupplierTarget.classList.add('hide');
    }
  }

  validateUniqueRut(event) {
    const supplierRut = document.getElementById('supplier_rut').value;
    const priceInput = document.getElementById('service_billing_price');
    const autonumericElem = AutoNumeric.getAutoNumericElement(priceInput)
    if (autonumericElem) {
      priceInput.value = autonumericElem.getNumber();
    }
    this.convertToRawValues();
    if (supplierRut.length == 0) return this.serviceBillingFormTarget.submit();
    const communityId = event.target.dataset.communityId;
    const countryCode = event.target.dataset.countryCode;

    let params = new URLSearchParams({ rut: JSON.stringify(supplierRut), community_id: JSON.stringify(communityId), country_code: JSON.stringify(countryCode) });

    return fetch('/validate_unique_rut.json' + '?' + params, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.exists) {
        $('#warning-unique-modal').modal('show');
      } else {
        this.serviceBillingFormTarget.submit();
      }
    });
  }

  removeOptions (selector) {
    const options = selector.parentElement.getElementsByClassName('dropdown-item-selectable')
      Array.from(options).forEach((option) => {
      option.parentElement.removeChild(option)
    });
  }

  convertToRawValues() {
    const autonumericInputs = document.querySelectorAll('input[data-autonumeric]')
    autonumericInputs.forEach((input) => {
      let e = AutoNumeric.getAutoNumericElement(input)
      input.value = e.rawValue
    })
  }

  selectSubcategory (selector) {
    const subcategory = selector.target.value
    const categorySelect = document.getElementById('category_name-hidden-value');
    const inputCategoryName = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    const categoryForm = document.getElementById('new_category');

    if (subcategory === '0') {
      categoryForm.classList.remove('hide')
      if (categorySelect.value) {
        inputCategoryName.value = categorySelect.value
        inputCategoryName.classList.add('disabled')
      }
      subcategoryNameInput.value = ''
    } else {
      subcategoryNameInput.value = subcategory
      inputCategoryName.value = categorySelect.value
      categoryForm.classList.add('hide')
      inputCategoryName.classList.add('disabled')
    }
  }

  resetInput() {
    const categoryNameInput = document.getElementById('category_name');
    const subcategoryNameInput = document.getElementById('category_sub_name');
    categoryNameInput.value = ''
    subcategoryNameInput.value = ''
  }

  // suggestions logic

  checkInput(event) {
    const query = event.target.value.trim();
    this.checkQueryLength(query);
    if (this.checkQueryLength(query) == false) {
      this.clearSuggestions();
      this.disableSuggestion();
      return
    }
    this.enableSuggestion();
  }

  checkQueryLength(query) {
    if (query.length < 3) {
      return false;
    }
    return true
  }

  disableSuggestion() {
    this.clearSuggestions();
    this.suggestBtnTarget.classList.toggle('disabled',true)
    this.suggestionsTarget.parentElement.classList.toggle('open',false)
    this.suggestBtnTextTarget.classList.toggle('hidden', false)
  }

  enableSuggestion() {
    this.suggestBtnTarget.classList.toggle('disabled',false)
    this.suggestBtnTextTarget.classList.toggle('hidden', true)
    this.inputDescriptionTarget.classList.toggle('without-suggestion', false)
  }

  suggest() {
    const query = this.inputDescriptionTarget.value.trim();
    if (this.checkQueryLength(query) == false) {
      this.disableSuggestion();
      return
    }

    // Realiza la petición al backend (ajusta la URL si es necesario)
    // Tengo que acomodar las traducciones de los endpoints para que esto funcione
    const categoryId = document.getElementById('category_name-hidden-value').value;
    const supplierId = document.getElementById('supplier_id-hidden-value').value;
    const url = this.translateRoute('suggestions_service_billings', { query: query, category_id: categoryId, supplier_id: supplierId });
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
      .then(response => response.json())
      .then(data => this.showSuggestions(data))
      .catch(error => console.error("Error fetching suggestions:", error));
  }

  showSuggestions(data) {
    const suggestions = data.suggestions;
    if (suggestions && suggestions.length > 0) {
      const suggestionsHTML = suggestions.map(suggestion =>
        `<div class="dropdown-item suggestions-item" data-action="click->service-billings-form#selectSuggestion">
            <div class="fa fa-wand-magic-sparkles"></div>
            <span>${suggestion}</span>
          </div>`
      ).join("");

      this.suggestionsTarget.parentElement.classList.toggle('open',true)
      this.suggestionsTarget.innerHTML = suggestionsHTML;
    } else {
      this.clearSuggestions();
      if (this.checkQueryLength(this.inputDescriptionTarget.value.trim()) == false) {
        this.suggestBtnTarget.classList.toggle('disabled',true)
        this.suggestionsTarget.parentElement.classList.toggle('open',false)
      } else {
        const html = `<div class="dropdown-item suggestions-item" style="cursor: not-allowed;">
              <div class="fa fa-wand-magic-sparkles" style="cursor: not-allowed"></div>
              <span> ${I18n.t('views.service_billings.form.no_suggestion')} </span>
            </div>
          `;
        this.suggestionsTarget.parentElement.classList.toggle('open',true)
        this.suggestionsTarget.innerHTML = html;
      }
    }
  }

  selectSuggestion(event){
    const suggestion = event.target.textContent.trim();
    this.inputDescriptionTarget.value = suggestion;
    this.clearSuggestions();
    this.suggestionsTarget.parentElement.classList.toggle('open',false)
    this.trackSlection();
  }

  clearSuggestions() {
    this.suggestionsTarget.innerHTML = "";
  }

  trackSlection() {
    trackEvent('ServiceBilling',
      { action: 'select historical description suggestion', community_id: this.communityIdValue  } );
  }
}
