import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["modal"];

  showModal(event) {
    const { approvalId, formattedPeriod, approverAction, approverDecision, decisionType, approverActionType } = event.currentTarget.dataset;
    this.modalSelector = `#approval-decision-modal-${approvalId}`;
    this.approverDecision = approverDecision;
    const decisionTypeObject = JSON.parse(decisionType);
    const approverActionTypeObject = JSON.parse(approverActionType);
    this.formattedPeriod = formattedPeriod;
    this.setActions(approverAction, approverActionTypeObject);
    this.setApproverDecisions(approverDecision, decisionTypeObject);
    this.setDecisionFieldValue();
    this.setConfirmButton();
    this.setCommentsInput();
    this.updateModalContent();
    $(this.modalSelector).modal("show");
  }

  closeModal() {
    $(this.modalSelector).modal("hide");
  }

  setActions(approverAction, approverActionType) {
    this.isApproveAction = approverAction === approverActionType.approve;
    this.isRejectAction = approverAction === approverActionType.reject;
    this.isEditAction = approverAction === approverActionType.edit;
  }

  setApproverDecisions(approverDecision, decisionType) {
    this.isApprovedDecision = approverDecision === decisionType.approved;
    this.isRejectedDecision = approverDecision === decisionType.rejected;
  }

  setDecisionFieldValue() {
    const decisionField = document.querySelector(this.modalSelector).querySelector('#common_expense_approval_user_decision');
    decisionField.value = this.approverDecision;
  }

  setConfirmButton() {
    this.confirmButton = document.querySelector(this.modalSelector).querySelector("#confirm-decision-btn");
  }

  setCommentsInput() {
    this.commentsInput = document.querySelector(this.modalSelector).querySelector('#common_expense_approval_user_comments');
    this.commentsInput.value = "";
  }

  submitForm(event) {
    event.preventDefault();
    this.toggleSubmitBtn().disable();
    const form = document.querySelector(this.modalSelector).querySelector("form");
    if (form) Turbo.navigator.submitForm(form);
    this.closeModal();
  }

  updateModalContent() {
    this.updateTitle();
    this.updateDescription();
    this.updateCommentsLabel();
    this.updateConfirmButton();
  }

  updateTitle() {
    const title = document.querySelector(this.modalSelector).querySelector(".approval-decision-title");

    if (!title) return;

    if (this.isApproveAction) {
      title.textContent = I18n.t("views.common_expense_approvals.approval_decision_modal.title.approval")
    } else if (this.isRejectAction) {
      title.textContent = I18n.t("views.common_expense_approvals.approval_decision_modal.title.rejection")
    } else if (this.isEditAction) {
      title.textContent = I18n.t("views.common_expense_approvals.approval_decision_modal.title.modify_approval")
    }
  }

  updateConfirmButton() {
    if (!this.confirmButton) return;

    if (this.isApproveAction) this.toggleSubmitBtn().enable();
    if (this.isRejectAction || this.isEditAction) this.toggleSubmitBtn().disable();

    if (this.isRejectAction || (this.isEditAction && this.isRejectedDecision)) {
      this.confirmButton.textContent = I18n.t("views.common_expense_approvals.approval_decision_modal.buttons.confirm_rejection");
      this.confirmButton.classList.remove("btn-green-cf");
      this.confirmButton.classList.add("btn-red-cf");
    } else {
      this.confirmButton.textContent = I18n.t("views.common_expense_approvals.approval_decision_modal.buttons.confirm_approval");
      this.confirmButton.classList.remove("btn-red-cf");
      this.confirmButton.classList.add("btn-green-cf");
    }
  }

  updateCommentsLabel() {
    const commentsLabel = document.querySelector(this.modalSelector).querySelector(".comments-description-label");
    if (!commentsLabel) return;
    commentsLabel.innerHTML = I18n.t(`views.common_expense_approvals.approval_decision_modal.form.${this.getCommentKey()}`);
  }

  updateDescription() {
    const description = document.querySelector(this.modalSelector).querySelector(".approval-decision-description");

    description.innerHTML = this.translatedPeriod(this.formattedPeriod);
  }

  translatedPeriod(period) {
    if (this.isRejectAction) return I18n.t("views.common_expense_approvals.approval_decision_modal.description.rejection", { period });
    if (this.isEditAction && this.isRejectedDecision) return I18n.t("views.common_expense_approvals.approval_decision_modal.description.edit_approval", { period });
    if (this.isEditAction && this.isApprovedDecision) return I18n.t("views.common_expense_approvals.approval_decision_modal.description.edit_rejection", { period });

    return I18n.t("views.common_expense_approvals.approval_decision_modal.description.approval", { period });
  }

  getCommentKey() {
    if (this.isRejectAction || (this.isEditAction && this.isRejectedDecision)) return "reasons_required_field_label";
    if (this.isEditAction && this.isApprovedDecision) return "comments_required_field_label";
    return "comments_optional_field_label";
  }

  checkValidations() {
    const maxLength = 100;
    const commentsLength = this.commentsInput.value.length;
    const isEmptyComments = this.commentsInput.value.replace(/\s/g, "") === "";

    if (commentsLength >= maxLength) {
      this.commentsInput.value = this.commentsInput.value.substring(0, maxLength);
    }

    if (isEmptyComments && !this.isApproveAction) {
      this.toggleSubmitBtn().disable();
    } else {
      this.toggleSubmitBtn().enable();
    }
  }

  toggleSubmitBtn() {
    return {
      enable: () => { 
        this.confirmButton.classList.remove("disabled");
        this.confirmButton.disabled = false;
      },
      disable: () => { 
        this.confirmButton.classList.add("disabled");
        this.confirmButton.disabled = true;
      }
    };
  }
}
