import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'


export default class extends Controller {
  static values = { bonusFactor: Number };
  static targets = [ 'postTaxInput', 'preTaxInput', 'postTax' ];

  addBono() {
    var new_bono = this.element.closest('.additional_info')
    if (verify_empty_inputs(new_bono)) {
      var index = parseInt(this.element.getAttribute('start_at'))
      var empty_bono = new_bono.cloneNode(true)
      empty_bono.querySelector('.addAdditionalInfo').setAttribute('start_at', index + 1)
      var new_inputs = empty_bono.getElementsByTagName('input')
      for (let i = 0; i < new_inputs.length; i++) {
        new_inputs[i].value = ''
        if (!new_inputs[i].name.includes('final_price')) new_inputs[i].removeAttribute('readonly')
        if (['checkbox', 'radio'].includes(new_inputs[i].type)) new_inputs[i].checked = false
      }
      var old_inputs = new_bono.getElementsByTagName('input')
      for (let i = 0; i < old_inputs.length; i++) {
        old_inputs[i].id = old_inputs[i].id.replace('index', index)
        old_inputs[i].name = old_inputs[i].name.replace('{index}', index)
      }
      new_bono.insertAdjacentElement('afterend', empty_bono)
      new_bono.querySelector('.addAdditionalInfo').style.display = 'none'
      new_bono.querySelector('.deleteAdditionalInfo').style.display = 'inline-block'
      $("[data-toggle='tooltip']").tooltip();

      $.reloadAllAutonumeric()
    }
  }

  destroyBono() {
    let bono_row = this.element.closest('.additional_info')
    bono_row.remove()
  }

  setPostTaxValue() {
    const value = AutoNumeric.getAutoNumericElement(this.preTaxInputTarget).getNumber();
    const new_value = value * this.bonusFactorValue;
    AutoNumeric.getAutoNumericElement(this.postTaxInputTarget).set(new_value);
    if (value != 0) {
      this.postTaxInputTarget.setAttribute('readonly', true);
    } else {
      this.postTaxInputTarget.removeAttribute('readonly');
    }
    this.postTaxTarget.value = false;
  }

  setPreTaxValue() {
    const value = AutoNumeric.getAutoNumericElement(this.postTaxInputTarget).getNumber();
    const new_value = value / this.bonusFactorValue;
    AutoNumeric.getAutoNumericElement(this.preTaxInputTarget).set(new_value);
    if (value != 0) {
      this.preTaxInputTarget.setAttribute('readonly', true);
    } else {
      this.preTaxInputTarget.removeAttribute('readonly');
    }
    this.postTaxTarget.value = true;
  }
}
