import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["warnings", "employees", "createServiceBilling", "validPeriodExpense"];

  connect() {
    this.checkInitialConditions();
  }

  checkInitialConditions() {
    const warningsEmpty = this.warningsTarget.dataset.empty === "true";
    const employeesEmpty = this.employeesTarget.dataset.empty === "true";
    const serviceBillingValid = !this.validPeriodExpenseTarget.disabled || !this.createServiceBillingTarget.checked

    if (warningsEmpty && employeesEmpty && serviceBillingValid) {
      const checkBox = document.getElementById("confirm-continue");
      const continueButton = document.getElementById("continue-button");

      if (checkBox && continueButton) {
        checkBox.checked = true;
        continueButton.disabled = false;
      }
    }
  }

  checkConditions() {
    const serviceBillingValid = !this.validPeriodExpenseTarget.disabled || !this.createServiceBillingTarget.checked
    const checkBox = document.getElementById("confirm-continue");

    const continueButton = document.getElementById("continue-button");

    continueButton.disabled = !(checkBox.checked && serviceBillingValid);
  }
}
