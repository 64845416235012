import BaseController from './base_controller'
import I18n from 'i18n-js'

export default class extends BaseController {
  static targets = ['pictureDisplayer', 'loaderBox', 'fileInput', 'takePictureButton', 'canvas', 'swap', 'snap', 'cancelButton', 'tooltip']
  static values = { picturesCount: Number, maxPicturesAllowed: Number }

  connect() {
    this.addLocale();
    this.videoDevicesIds = [];
    this.initialized = false;
    this.stream = null;
    
    this.modalElement = document.getElementById('photo_modal');
    this.boundStopTracks = this.stopTracks.bind(this);
    this.onModalDismissDo(this.modalElement, this.boundStopTracks);
  }

  disconnect() {
    this.onModalDismissDo(this.modalElement, this.boundStopTracks, false);
  }

  tooltipTargetsConnected(element) {
    $(element).tooltip();
  }

  stopTracks() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  selectPicture() {
    this.fileInputTarget.click()
  }

  appendBox(pictureInput) {
    let boxElement = document.createElement('div')
    boxElement.classList.add('picture-box')
    this.appendRemoveButton(boxElement)
    boxElement.appendChild(pictureInput)
    this.pictureDisplayerTarget.insertBefore(boxElement, this.loaderBoxTarget)
    this.updateCounter(1)
  }

  appendRemoveButton(box) {
    let button = document.createElement('div')
    button.classList.add('btn', 'btn-default', 'btn-s', 'remove-button')
    button.setAttribute('data-action', 'click->picture-input#removePicture')
    button.setAttribute('data-picture-input-target', 'tooltip')
    button.setAttribute('data-toggle', 'tooltip')
    button.setAttribute('data-title', I18n.t('common.delete'))
    let icon = document.createElement('i')
    icon.classList.add('fa', 'fa-trash')
    button.appendChild(icon)
    this.tooltipTargetsConnected(button)
    box.appendChild(button)
  }

  removePicture(event) {
    event.currentTarget.closest('.picture-box').remove()
    this.updateCounter(-1)
  }

  uploadPicture() {
    const pictures = this.fileInputTarget.files
    if (pictures.length > 0) {
      const picture = pictures[0]
      if (this.validPicture(picture)) {
        this.appendPicture(pictures[0])
      } else {
        this.displayMessage()
      }
    }
  }

  validPicture(picture) {
    let extension = picture.name.split('.').pop().toLowerCase()
    return ['gif', 'img', 'jpg', 'jpeg', 'png'].includes(extension)
  }

  displayMessage() {
    let flashDiv = document.getElementById('flash')
    let flashMessageDiv = document.createElement('div')
    flashMessageDiv.classList.add('flash__error')
    let flashIcon = document.createElement('div')
    flashIcon.classList.add('flash-icon')
    let icon = document.createElement('div')
    icon.classList.add('fa', 'fa-times')
    flashIcon.appendChild(icon)
    flashMessageDiv.appendChild(flashIcon)
    let flashMessage = document.createElement('div')
    flashMessage.classList.add('flash-message')
    flashMessage.innerHTML = I18n.t('components.picture_input.error.not_allowed_extension')
    flashMessageDiv.appendChild(flashMessage)
    flashDiv.append(flashMessageDiv)
  }

  appendPicture(picture) {
    const newPicture = this.createPictureInput(picture)
    this.appendBox(newPicture)
  }

  createPictureInput(picture) {
    let pictureDiv = document.createElement('div')
    let reader = new FileReader();
    reader.onload = function(file) {
      let img = new Image();
      img.src = file.target.result;
      img.style.height = 'inherit'
      pictureDiv.appendChild(img);
    }
    reader.readAsDataURL(picture);
    const newPictureInput = document.createElement('input')
    newPictureInput.type = 'file'
    const newPictureList = new DataTransfer()
    newPictureList.items.add(picture)
    newPictureInput.files = newPictureList.files
    newPictureInput.style.display = 'none'
    newPictureInput.name = `assets[${this.picturesCountValue}][document]`
    newPictureInput.id = `assets_${this.picturesCountValue}_document`
    pictureDiv.appendChild(newPictureInput)

    return pictureDiv
  }

  updateCounter(value) {
    this.picturesCountValue += value
    if (this.hasMaxPicturesAllowedValue) {
      if (this.picturesCountValue >= this.maxPicturesAllowedValue) {
        this.loaderBoxTarget.style.display = 'none'
      } else {
        this.loaderBoxTarget.removeAttribute('style')
      }
    }
  }

  createPictureFromBlob() {
    const self = this
    const canvas = this.canvasTarget
    canvas.toBlob(
      blob => {
        const file = new File([blob], 'dot.png', blob)
        self.appendPicture(file)
      },
      'image/png'
    );
    this.stopTracks();

    $('#canvas_modal').modal('hide');
    $('#photo_modal').modal('hide');
  }

  takeSnap() {
    const canvas = this.canvasTarget
    const context = canvas.getContext('2d');
    const video = document.querySelector('video#video');

    context.canvas.height = video.videoHeight;
    context.canvas.width = video.videoWidth;

    $('#canvas_modal').modal();

    context.drawImage(video, 0, 0, context.canvas.width, context.canvas.height);
    document.querySelector('#canvas_modal').style.padding = '0';
  }

  async captureCamera(cameraId = null) {
    // Base constraints with more flexible resolution
    let constraints = {
      audio: false,
      video: {}
    };

    // If a specific camera ID is provided, use it
    if (cameraId) {
      constraints.video.deviceId = { exact: cameraId };
    } 
    // Otherwise for initial capture, prefer environment facing camera (back camera)
    else {
      constraints.video.facingMode = { ideal: 'environment' };
    }

    // Add optional constraints that won't cause failures if not supported
    constraints.video.width = { ideal: 1280 };
    constraints.video.height = { ideal: 720 };

    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      
      // Fallback to basic constraints if the above fails
      const fallbackConstraints = {
        audio: false,
        video: cameraId ? { deviceId: { exact: cameraId } } : true
      };
      
      return await navigator.mediaDevices.getUserMedia(fallbackConstraints);
    }
  }

  async getDevices() {
    const self = this;
    try {
      // First try to get camera access to ensure permissions
      const stream = await this.captureCamera();
      stream.getTracks().forEach(track => track.stop());
      
      // Then enumerate devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      let videoDevices = devices.filter(device => device.kind == 'videoinput');


      if (videoDevices.length <= 1) {
        $('#camera-options').remove();
      } else {
        // Clear existing options first
        self.swapTarget.innerHTML = '';
        
        // Add a default option camera
        self.swapTarget.append(new Option(I18n.t('components.picture_input.default'), 'environment'));
        
        // Add all available cameras
        videoDevices.forEach((device) => {
          self.swapTarget.append(new Option(device.label || `Cámara ${device.deviceId.substring(0, 5)}...`, device.deviceId));
        });
      }

      this.videoDevicesIds = videoDevices.map(function (device) {
        return device.deviceId;
      });
      // Add special 'environment' option for back camera
      this.videoDevicesIds.unshift('environment');
    } catch (error) {
      triggerAlert(I18n.t('components.picture_input.error.camera_access_denied'));
      allow_multiple_alerts();
    }
  }

  swapCamera() {
    const self = this;
    async function init() {
      try {
        if (self.stream) {
          self.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
        
        const selectedValue = self.swapTarget.value;
        
        // Special handling for 'environment' option
        if (selectedValue === 'environment') {
          // Use facingMode constraint directly for back camera
          const constraints = {
            audio: false,
            video: {
              facingMode: { exact: 'environment' }
            }
          };
          self.stream = await navigator.mediaDevices.getUserMedia(constraints);
        } else {
          // Otherwise use the device ID
          self.stream = await self.setCamera(selectedValue);
        }
        
        self.handleSuccess(self.stream);
      }
      catch (e) {
        triggerAlert(I18n.t('components.picture_input.error.camera_switch_error'));
        allow_multiple_alerts();
      }
    }
    init();
  }

  async setCamera(cameraId) {
    return await this.captureCamera(cameraId)
  }

  handleSuccess(stream) {
    const video = document.querySelector('video#video');
    window.stream = stream;
    video.srcObject = stream;
    
    // Make sure video element is visible and properly sized
    video.style.display = 'block';
    video.style.width = '100%';
    video.style.backgroundColor = '#000';
    
    // Add event listener to handle when video is actually playing
    video.addEventListener('playing', () => {
    });
    
    // Enable the snap button
    this.snapTarget.removeAttribute('disabled');
    this.snapTarget.setAttribute('data-action', 'click->picture-input#takeSnap');
  }
  
  async init() {
    try {
      // Try to use the back camera by default
      const constraints = {
        audio: false,
        video: {
          facingMode: { ideal: 'environment' }
        }
      };
      
      try {
        // First try with environment facing mode
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      } catch (envError) {
        // Fallback to any camera
        this.stream = await this.setCamera();
      }
      
      this.handleSuccess(this.stream);
    }
    catch (e) {
      triggerAlert(I18n.t('components.picture_input.error.camera_init_error'));
      allow_multiple_alerts();
    }
  }

  takePicture() {
    try {
      // Reset state each time to ensure fresh camera initialization
      this.stopTracks();
      this.initialized = false;
      
      // Initialize camera and get devices
      if (!this.initialized) {
        this.initialized = true;
        this.getDevices();
      }
      
      this.init();

      // Show the modal after initialization
      $('#photo_modal').modal();
    } catch (error) {
      triggerAlert(I18n.t('components.picture_input.error.camera_general_error'));
      allow_multiple_alerts();
    }
  }

  startDeleting(event) {
    event.preventDefault()
    let button = event.currentTarget
    let pictureBox = button.closest('.picture-box')
    let self = this
    let url = ''
    url = button.getAttribute('url')

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          pictureBox.remove()
          let flash = document.getElementById('flash')
          let flashSuccess = document.createElement('div')
          let flashIconDiv = document.createElement('div')
          flashIconDiv.classList.add('flash-icon')
          let flashIcon = document.createElement('div')
          flashIcon.classList.add('fa', 'fa-check')
          flashIconDiv.appendChild(flashIcon)
          let flashMessage = document.createElement('div')
          flashMessage.classList.add('flash-message')
          flashSuccess.classList.add('flash__success')
          flashMessage.innerHTML = I18n.t('components.file_input.message.file_deleted_successfully')
          flashSuccess.appendChild(flashIconDiv)
          flashSuccess.appendChild(flashMessage)
          flash.appendChild(flashSuccess)
          self.loaderBoxTarget.removeAttribute('style')
        } else {
          console.log(data)
        }
      })
  }
}
