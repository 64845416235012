import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "label"];

  connect() {
    this.update();

    setTimeout(() => {
      this.update();
    }, 100);
  }

  update() {
    if (this.inputTarget.value.trim() !== "") {
      this.labelTarget.classList.add("active");
    } else {
      this.labelTarget.classList.remove("active");
    }
  }

  handleInput() {
    this.update();
  }
}
