import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'icon', 'show', 'hidden']

  initialize() {
    this.hidden = true;
  }

  toggle(event) {
    if (this.hidden) {
      this.hidden = false

      this.inputTarget.type = 'text'
      if (this.hasShowTarget) {
        this.hiddenTarget.classList.add('hidden')
        this.showTarget.classList.remove('hidden')
      } else {
        this.iconTarget.classList.remove('fa-eye-slash')
        this.iconTarget.classList.add('fa-eye')
      }
    } else {
      this.hidden = true

      this.inputTarget.type = 'password'
      if (this.hasShowTarget) {
        this.hiddenTarget.classList.remove('hidden')
        this.showTarget.classList.add('hidden')
      } else {
        this.iconTarget.classList.remove('fa-eye')
        this.iconTarget.classList.add('fa-eye-slash')
      }
    }
  }
}
