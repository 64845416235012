import I18n from "i18n-js";
import { Controller } from "stimulus";
import fetchJsonWithCSRF from "../../utils/fetcher";

export default class extends Controller {
  static targets = [
    "roleDisplay",
    "roleOption",
    "dropdownTrigger",
  ];
  static values = { role: String, requestId: String };

  async updateRole(event) {
    event.preventDefault();
    let target = event.target;
    do {
      if (target.dataset.role) break;
      target = target.parentElement;
    } while (target);
    if (!target) return;

    const newRole = target.dataset.role;
    if (newRole === this.roleValue) return;

    this.toggleDropdownTriggerDisabled(true);

    try {
      const url = target.dataset.url;
      const { response } = await fetchJsonWithCSRF.put(url);

      this.updateRoleDisplay(response);
      this.toggleRoleOptionSelected(response);
      this.updateModalRole(response);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      this.toggleDropdownTriggerDisabled(false);
    }
  }

  toggleDropdownTriggerDisabled(disabled) {
    this.dropdownTriggerTarget.classList.toggle(
      "dropdown_trigger_disabled",
      disabled
    );
  }

  updateRoleDisplay(response) {
    const roleText = I18n.t(`constants.property_users.roles.${response}`);
    this.roleDisplayTarget.textContent = roleText;
    this.roleValue = response;
  }

  toggleRoleOptionSelected(response) {
    this.roleOptionTargets.forEach((span) => {
      span.classList.toggle("role_selected", span.dataset.role === response);
    });
  }

  updateModalRole(response) {
    const roleText = I18n.t(`constants.property_users.roles.${response}`);
    const modalRoleElement = document.querySelector(`span.modal-role[data-request-id="${this.requestIdValue}"]`);
    if (modalRoleElement) {
      modalRoleElement.textContent = roleText;
    }
  }
}
