import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["email", "link"];

  updateLink() {
    const email = this.emailTarget.value.trim();
    const url = this.linkTarget.getAttribute("data-base-url");

    if (email) {
      this.linkTarget.href = `${url}?email=${encodeURIComponent(email)}`;
    } else {
      this.linkTarget.href = url;
    }
  }
}

