import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  call(event){
    this.toggle()
  }

  toggle(){
    let attribute = this.inputTarget.dataset.attribute
    this.inputTarget.toggleAttribute(attribute)
  }

  toggleClass(){
    let classToToggle = this.inputTarget.dataset.class
    this.inputTarget.classList.toggle(classToToggle)
  }

  toggleIcon(event) {
    const icon = event.currentTarget;
    const collapseId = icon.getAttribute('href');
    const row = document.querySelector(collapseId);
    const isOpen = row.classList.contains('in');
    icon.classList.remove('fa-angle-down', 'fa-angle-up');

    if (isOpen) {
      icon.classList.add('fa-angle-down');
    } else {
      icon.classList.add('fa-angle-up');
    }
  }
}
