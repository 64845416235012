import { Controller } from '@hotwired/stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static values = {
    suggestionId: String,
  }

  static targets = ['suggestionButton', 'suggestionButtonContent']

  connect() {
    const defaultText =
      this.element.dataset.defaultText ||
      I18n.t('views.posts.form.ai_content_suggestion')

    if (!this.hasSuggestionIdValue || this.suggestionIdValue === '') {
      this.updateButtonContent(defaultText, 'fa-solid fa-wand-magic-sparkles')
      this.updateButtonStyle('default')
      return
    }

    this.updateButtonContent(
      I18n.t('views.posts.form.ai_thinking'),
      'fa-solid fa-hourglass-half'
    )
    this.updateButtonStyle('pending')
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    if (!this.pollInterval) {
      this.pollInterval = setInterval(() => {
        this.checkSuggestionStatus()
      }, 1000)
    }
  }

  stopPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
      this.pollInterval = null
    }
  }

  checkSuggestionStatus() {
    const url = `/posts/suggestion_status?suggestion_id=${this.suggestionIdValue}`

    fetch(url, { headers: { Accept: 'application/json' } })
      .then((resp) => resp.json())
      .then((data) => {
        const { status, raw_response } = data

        if (status === 'pending') {
          this.updateButtonContent(
            I18n.t('views.posts.form.ai_thinking'),
            'fa-solid fa-hourglass-half'
          )
          this.updateButtonStyle('pending')
        } else {
          this.stopPolling()

          if (status === 'completed') {
            this.updateButtonContent(
              I18n.t('views.posts.form.ai_suggestion_ready'),
              'fa-solid fa-circle-check'
            )
            this.updateButtonStyle('success')
            this.fillForm(raw_response)
          } else if (status === 'failed') {
            this.updateButtonContent(
              I18n.t('views.posts.form.ai_suggestion_error'),
              'fa-solid fa-rotate'
            )
            this.updateButtonStyle('error')

            const errorMessage = raw_response['invalid_reason']

            let suggestionButton = this.suggestionButtonTarget
            if (suggestionButton) {
              let url = new URL(suggestionButton.href)
              url.searchParams.set('invalid_reason', errorMessage)
              suggestionButton.href = url.toString()

              setTimeout(() => {
                suggestionButton.click()
              }, 100)
            }
          }
        }
      })
      .catch(() => {
        this.updateButtonContent(
          I18n.t('views.posts.form.ai_connection_error'),
          'fa-solid fa-rotate'
        )
        this.updateButtonStyle('error')
        this.stopPolling()
      })
  }

  fillForm(rawResponse) {
    const titleInput = document.getElementById('post_title')
    if (titleInput && rawResponse.title) {
      titleInput.value = rawResponse.title
    }

    if (
      typeof tinymce !== 'undefined' &&
      tinymce.activeEditor &&
      rawResponse.content
    ) {
      tinymce.activeEditor.setContent(rawResponse.content)
      setTimeout(() => {
        enableToogleCreateBtn();
      }, 2000);
    }
  }

  updateButtonContent(text, iconClass) {
    if (this.hasSuggestionButtonContentTarget) {
      this.suggestionButtonContentTarget.innerHTML = `<i class='${iconClass} pl-10'></i> ${text}`
    }
  }

  updateButtonStyle(status) {
    const button = this.suggestionButtonTarget

    button.classList.remove(
      'btn-ai-default',
      'btn-ai-pending',
      'btn-ai-success',
      'btn-ai-error'
    )

    if (status === 'pending') {
      button.classList.add('btn-ai-pending')
    } else if (status === 'success') {
      button.classList.add('btn-ai-success')
    } else if (status === 'error') {
      button.classList.add('btn-ai-error')
    } else {
      button.classList.add('btn-ai-default')
    }
  }
}
