import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['modal', 'title', 'body', 'shareModal', 'shareButton']
  static values = { url: String }

  connect() {
    const community_language = document.querySelector('#community_language').value;
    if (community_language) {
      I18n.locale = community_language
    }
  }

  buildModal(event) {
    let url = event.currentTarget.dataset.url
    Turbo.visit(url, { turbo: true, acceptsStreamResponse: true, frame: 'post-show' })
    $(this.modalTarget).modal()
  }

  showShareModal(event) {
    let beginning = I18n.t('views.posts.share.whatsapp_message.beginning')
    let middle = I18n.t('views.posts.share.whatsapp_message.middle').replace('{message}', event.currentTarget.dataset.postTitle)
    let ending = I18n.t('views.posts.share.whatsapp_message.ending')
    let link = I18n.t('views.posts.share.whatsapp_message.link').replace('{url}', this.urlValue)
    let text = encodeURIComponent(beginning) + '%0A%0A' + encodeURIComponent(middle) + '%0A%0A' + encodeURIComponent(ending) + '%0A%0A' + encodeURIComponent(link)
    let baseUrl = "https://api.whatsapp.com/send?text="
    let url = baseUrl + text
    this.shareButtonTarget.href = url
    $(this.shareModalTarget).modal()
  }

  sharePost() {
    $(this.shareModalTarget).modal('hide')
  }
}
