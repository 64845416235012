import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "postPublishDate", "postPublishTime", "scheduleAtError", "errorEventStart", "publishedCheckbox", "aliquotCheckboxes", "allRecipients" ]

  connect(){
    this.validateChekbox();
    this.initializeDate();
    this.addWarning();
    this.validateChekboxSchedule();
  }

  initializeDate(event){
    let conference_errors = document.getElementById('errors').value
    let post_record = document.getElementById('post_record').value

    if(post_record == 'true' && conference_errors == ''){
      document.getElementById('conference_event_start-selector-input').value = ''
      document.getElementById('conference_event_end-selector-input').value = ''
    }

  }

  validateChekbox(event){
    let conference_check = document.getElementById('post_add_conference')

    conference_event_start.disabled = conference_check?.checked ? false : true
  }

  validateChekboxSchedule(){
    let schedule_check = document.getElementById('post_schedule')
    let publish_at = document.getElementById('publish_at')

    if (schedule_check.checked){
      $(publish_at).show()
      this.publishedCheckboxTarget.classList.add('hidden')
      this.aliquotCheckboxesTarget.classList.add('hidden')
      this.allRecipientsTarget.classList.add('hidden')
    }
    else {
      $(publish_at).hide()
      this.publishedCheckboxTarget.classList.remove('hidden')
      this.aliquotCheckboxesTarget.classList.remove('hidden')
      this.allRecipientsTarget.classList.remove('hidden')
    }

  }

  addWarning(event){
    let conference_errors = document.getElementById('errors').value
    let event_start_selector = document.getElementById('conference_event_start-selector-input')
    let event_end_selector = document.getElementById('conference_event_end-selector-input')
    let post_publish_date = this.postPublishDateTarget.parentElement.querySelector('#post_publish_date-selector-input')

    if(conference_errors == 'event_start'){
      event_start_selector.classList.add('invalid_input')
      this.errorEventStartTarget.style.display = 'block';
    }else if(conference_errors == 'event_end'){
      event_end_selector.classList.add('invalid_input')
    } else {
      this.errorEventStartTarget.style.display = 'none';
    }
    if(this.scheduleAtErrorTarget.value === 'true'){
      post_publish_date.classList.add('invalid_input')
      this.postPublishTimeTarget.classList.add('invalid_input')
    } else {
      post_publish_date.classList.remove('invalid_input')
      this.postPublishTimeTarget.classList.remove('invalid_input')
    }
  }

  changeDate(event){
    let event_start_selector = document.getElementById('conference_event_start-selector-input')
    let event_end_selector = document.getElementById('conference_event_end-selector-input')
    let input_end = document.getElementById('conference_event_end')
    let input_start = document.getElementById('conference_event_start')

    if(input_start && input_start.value != '' && input_end && event_start_selector && event_end_selector){
      // change in iso format
      let date = new Date(input_start.value);
      date.setHours(date.getHours() + 1);
      let newDateValue = date.toISOString().slice(0, 16);
      input_end.value = newDateValue;

      // change hour in input
      let format_date = event_start_selector.value.split('- ')
      let [hours, minutes] = format_date[1].split(':').map(Number);
      let newHours = (hours + 1) % 24;
      let formattedTime = `${newHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      let dates = format_date[0] + '- ' + formattedTime
      event_end_selector.value = dates
    }
  }
}
