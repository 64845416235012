import { Controller } from "stimulus"

export default class extends Controller {

  //  MUST:
  //
  //    - Add data: { 'currency-formatter-target': 'input' } to the corresponding input for autonumeric formatting.
  //
  //    - To inputTarget add formats to be applied
  //        Example: data: { formats: { currency: no_negative_currency_field[:autonumeric] } }
  //
  //    - To selector input add cases ( format => cases ) as follows:
  //        Example: data: { cases: { currency: [0], percent: [1,2] } }
  //                 Keys will be the name of the formats.
  //
  //  OPTIONAL:
  //
  //    - Add custom attributes for autonumeric
  //        Example: data: { formats: { custom: { percent: { mDec: 2, vMin: 0 } } } }
  //
  //    - To inputTarget add Placeholders
  //        Example: data: { placeholders: { percent: '12 %', currency: '$ 100.00' } }
  //
  //    - If you want to change label with selector, add target label and label options to label dom element as follows:
  //        Example: data: { 'currency-formatter-target': 'label', labels: { percent: 'Porcentaje', currency: 'Monto' } }

  static targets = ["input", "label"]

  connect() {
  }

  format_by_selector(event) {
    let cases = JSON.parse(event.currentTarget.dataset.cases)
    let format = Object.entries(cases).find((entry) => entry[1].includes(Number(event.currentTarget.value)) )[0]
    if (this.hasLabelTarget) { this.selector_format_label(format); }
    if (this.inputTarget.dataset.placeholders) { this.selector_format_placeholder(format); }
    this.selector_format_autonumeric(this.inputTarget, format);
  }

  format_by_selector_with_string_values(event) {
    let cases = JSON.parse(event.currentTarget.dataset.cases)
    let format = Object.entries(cases).find((entry) => entry[1].includes(event.currentTarget.value) )[0]
    if (this.hasLabelTarget) { this.selector_format_label(format); }
    if (this.inputTarget.dataset.placeholders) { this.selector_format_placeholder(format); }
    this.selector_format_autonumeric(this.inputTarget, format);
  }

  format_by_radio(event){
    let format = event.currentTarget.value
    if (this.hasLabelTarget) { this.selector_format_label(format); }
    if (this.inputTarget.dataset.placeholders) { this.selector_format_placeholder(format); }
    this.selector_format_autonumeric(this.inputTarget, format);
  }

  selector_format_label(format) {
    let labels = JSON.parse(this.labelTarget.dataset.labels)
    this.labelTarget.textContent = labels[format]
  }

  selector_format_placeholder(format) {
    let placeholders = JSON.parse(this.inputTarget.dataset.placeholders)
    let placeholder = placeholders[format]
    this.inputTarget.setAttribute('placeholder', placeholder);
  }

  selector_format_autonumeric(element, data) {
    if (this.inputTarget.dataset.formats) {
      let formats = JSON.parse(this.inputTarget.dataset.formats)
      let format = formats[data]
      if (formats.custom[data]) {
        Object.assign(format, formats.custom[data])
      }
      let data_autonumeric = JSON.stringify(format)
      element.dataset.autonumeric = data_autonumeric;
    }

    let new_field = element.outerHTML
    element.insertAdjacentHTML('afterend', new_field)
    element.remove();
    $.reloadAllAutonumeric();
  }
}
