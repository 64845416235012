import { Controller } from '@hotwired/stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['email', 'password', 'emailError', 'passwordError', 'passwordErrorBack', 'emailLabel', 'passwordLabel']
  static values = { emailRegex: String }

  connect() {
    this.element.removeEventListener('submit', this.validateForm.bind(this));
    this.element.addEventListener('submit', this.validateForm.bind(this));
  }

  validateForm(event) {

    const isEmailValid = this.validateEmail();
    const isPasswordValid = this.validatePassword();

    if (!isEmailValid || !isPasswordValid) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  validateEmail() {
    const email = this.emailTarget.value.trim();
    this.clearError(this.emailTarget, this.emailErrorTarget, this.emailLabelTarget);

    if (!email) {
      this.showError(this.emailTarget, this.emailErrorTarget, this.emailLabelTarget, I18n.t('modules.components.password_text_field.password_required'));
      return false;
    }

    const emailRegex = new RegExp(this.emailRegexValue);
    if (!email.match(emailRegex)) {
      this.showError(this.emailTarget, this.emailErrorTarget, this.emailLabelTarget, I18n.t('modules.components.email_text_field.invalid_format'));
      return false;
    }

    return true;
  }

  validatePassword() {
    const password = this.passwordTarget.value.trim();
    this.clearError(this.passwordTarget, this.passwordErrorTarget, this.passwordLabelTarget);

    if (!password) {
      this.showError(this.passwordTarget, this.passwordErrorTarget, this.passwordLabelTarget, I18n.t('modules.components.password_text_field.password_required'));
      return false;
    }

    return true;
  }

  showError(input, errorTarget, labelTarget, message) {
    errorTarget.textContent = '';
    errorTarget.textContent = message;
    input.classList.add('input-error');
    if (labelTarget) labelTarget.classList.add('label-error');
  }

  clearError(input, errorTarget, labelTarget) {
    errorTarget.textContent = '';
    input.classList.remove('input-error');
    if (labelTarget) labelTarget.classList.remove('label-error');
  }
}

