import BaseController from '../base_controller'
import fetchJsonWithCSRF from "../../utils/fetcher";

export default class extends BaseController {
  static targets = ['textArea', 'rejectButton', 'rejectModal', 'rejectModalRadioButton'];
  static values = { requestId: String };

  connect() {
    // The same bound function must be used when adding and removing listeners 
    this.boundDismissRejectModal = this.dismissRejectModal.bind(this);
    this.onModalDismissDo(this.rejectModalTarget, this.boundDismissRejectModal);
  }

  disconnect() {
    this.onModalDismissDo(this.rejectModalTarget, this.boundDismissRejectModal, false);
  }

  toggleTextArea(event) {
    if (event.target.value === 'custom') {
      this.textAreaTarget.disabled = false;
    } else {
      this.resetOtherReasonTextArea();
    }
    this.toggleRejectButton();
  }

  async reject(event) {
    event.preventDefault();
    const response = await fetchJsonWithCSRF.put(`/property_user_requests/${this.requestIdValue}/reject_access_request`, {
      body: JSON.stringify({ reason: this.getSelectedReason() })
    });
    
    if (response.url) {
      window.location.href = response.url;
    }
  }

  getSelectedReason() {
    const reason = this.rejectModalRadioButtonTargets.find(radio => radio.checked)?.value;
    return reason === 'custom' ? this.textAreaTarget.value.trim() : reason;
  }

  toggleRejectButton() {
    const validSelectionPresent = !!this.getSelectedReason(); // We cast the result to boolean
    this.rejectButtonTarget.disabled = !validSelectionPresent;
  }

  dismissRejectModal(_event) {
    this.resetOtherReasonTextArea();
    this.rejectModalRadioButtonTargets.forEach(radio => radio.checked = false);
    this.toggleRejectButton();
  }

  resetOtherReasonTextArea() {
    this.textAreaTarget.disabled = true;
    this.textAreaTarget.value = '';
  }
}
