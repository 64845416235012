import { Controller } from 'stimulus';
import fetchJsonWithCSRF from '../../utils/fetcher';
import I18n from 'i18n-js';

export default class extends Controller {
  static values = {
    identityModalId: String,
    ownershipModalId: String,
    confirmedDeleteIdentityModalId: String,
    confirmedDeleteOwnershipModalId: String,
    removeIdentityPath: String,
    removeOwnershipPath: String,
  };
  static targets = [
    'documentIdentityPreview',
    'documentOwnershipPreview',
    'deleteIdentityDocumentButton',
    'deleteOwnershipDocumentButton',
    'uploadIdentityDocumentButton',
    'uploadOwnershipDocumentButton',
    'downloadIdentityDocumentButton',
    'downloadOwnershipDocumentButton',
    'deleteIdentityConfirmationButton',
    'deleteOwnershipConfirmationButton',
  ];
  
  showIdentityDocumentModal() {
    $(`#${this.identityModalIdValue}`).modal('show');
  }

  showOwnershipDocumentModal() {
    $(`#${this.ownershipModalIdValue}`).modal('show');
  }

  async removeIdentityDocument() {
    this.deleteIdentityConfirmationButtonTarget.classList.add('disabled');
    try {
      await fetchJsonWithCSRF.delete(this.removeIdentityPathValue);
      $(`#${this.confirmedDeleteIdentityModalIdValue}`).modal('hide');
      this.updateIdentityDocumentView();
      this.refreshIdentityButtons();
      this.showIdentityDocumentModal();
    } catch (error) {
      alert(error);
    }
  }

  async removeOwnershipDocument() {
    this.deleteOwnershipConfirmationButtonTarget.classList.add('disabled');
    try {
      await fetchJsonWithCSRF.delete(this.removeOwnershipPathValue);
      $(`#${this.confirmedDeleteOwnershipModalIdValue}`).modal('hide');
      this.updateOwnershipDocumentView();
      this.refreshOwnershipButtons();
      this.showOwnershipDocumentModal();
    } catch (error) {
      alert(error);
    }
  }

  refreshIdentityButtons() {
    if (this.hasUploadIdentityDocumentButtonTarget) {
      this.uploadIdentityDocumentButtonTarget.classList.remove("hidden");
    }

    if (this.hasDeleteIdentityDocumentButtonTarget) {
      this.deleteIdentityDocumentButtonTarget.classList.add("hidden");
    }

    if (this.hasDownloadIdentityDocumentButtonTarget) {
      this.downloadIdentityDocumentButtonTarget.classList.add("hidden");
    }
  }

  refreshOwnershipButtons() {
    if (this.uploadOwnershipDocumentButtonTarget) {
      this.uploadOwnershipDocumentButtonTarget.classList.remove("hidden");
    }
    if (this.deleteOwnershipDocumentButtonTarget) {  
      this.deleteOwnershipDocumentButtonTarget.classList.add("hidden");
    }
    if (this.downloadOwnershipDocumentButtonTarget) {
      this.downloadOwnershipDocumentButtonTarget.classList.add("hidden");
    }
  }

  adminSelectedIdentityDocuments({ target }) {
    const files = [...target.files].filter(file => file);
    const isValid = files.length > 0 && files.length <= 2 && files.every(file => 
      ['image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024
    );

    if (isValid) {
      this.uploadIdentityDocumentButtonTarget.classList.add('disabled');
      this.uploadIdentityDocumentButtonTarget.click();
    } else {
      alert(I18n.t('views.property_users.delete_document.limit_chargue_id_card'));
      target.value = "";
    }
  }

  adminSelectedDocument({ target }) {
    const file = target.files[0];
    if (file && ['application/pdf', 'image/gif', 'image/jpeg', 'image/png'].includes(file.type) && file.size <= 5 * 1024 * 1024) {
      this.uploadOwnershipDocumentButtonTarget.classList.add('disabled');
      this.uploadOwnershipDocumentButtonTarget.click();
    } else {
      alert(I18n.t('views.property_users.delete_document.limit_chargue_document'));
      target.value = "";
    }
  }

  updateIdentityDocumentView() {
    if (this.hasDocumentIdentityPreviewTarget) {
      this.documentIdentityPreviewTarget.classList.add("bg-color-very-light-blue"); 
      this.documentIdentityPreviewTarget.innerHTML = I18n.t('views.property_users.delete_document.empty_identity_document_preview_html');
    }
    this.removeDownloadButton();
  }

  updateOwnershipDocumentView() {
    if (this.hasDocumentOwnershipPreviewTarget) {
      this.documentOwnershipPreviewTarget.classList.add("bg-color-very-light-blue");
      this.documentOwnershipPreviewTarget.innerHTML = I18n.t('views.property_users.delete_document.empty_ownership_document_preview_html');
    }
    this.removeDownloadButton();
  }

  removeDownloadButton() {
    const downloadButton = document.querySelector(".show-document-download-button");
    if (downloadButton) {
      downloadButton.remove();
    }
  }
}
