import BaseController from './base_controller'
import I18n from 'i18n-js';

export default class extends BaseController {
  static targets = ['titleInput', 'suggestBtn', 'suggestions', 'suggestBtnText'];

  connect() {
    document.addEventListener('click', this.closeDropdown.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.closeDropdown.bind(this));
  }

  checkInput(event) {
    const query = event.target.value.trim();
    if (this.checkQueryLength(query)) {
      this.enableSuggestion();
    } else {
      this.disableSuggestion();
    }
  }

  checkQueryLength(query) {
    return query.length >= 3;
  }

  disableSuggestion() {
    this.clearSuggestions();
    this.suggestBtnTarget.classList.add('disabled');
    this.suggestBtnTextTarget.classList.remove('hidden');
  }

  enableSuggestion() {
    this.suggestBtnTarget.classList.remove('disabled');
    this.suggestBtnTextTarget.classList.add('hidden');
  }

  suggest() {
    const query = this.titleInputTarget.value.trim();
    if (!this.checkQueryLength(query)) {
      this.disableSuggestion();
      return;
    }

    const url = this.translateRoute('suggestions_property_fines', { query: query });

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    })
      .then((response) => response.json())
      .then((data) => this.showSuggestions(data))
      .catch((error) => console.error('Error fetching suggestions:', error));
  }

  showSuggestions(data) {
    if (data.suggestions && data.suggestions.length > 0) {
      const suggestionsHTML = data.suggestions
        .map(
          (suggestion) => `
          <div class='dropdown-item suggestions-item' data-action='click->property-fines-form#selectSuggestion'>
            <span>${suggestion}</span>
          </div>`
        )
        .join('');

      this.suggestionsTarget.innerHTML = suggestionsHTML;
      this.suggestionsTarget.parentElement.classList.add('open');
    } else {
      this.clearSuggestions();
      this.suggestionsTarget.innerHTML = `<div class='dropdown-item suggestions-item' style='cursor: not-allowed;'>
          <span> ${I18n.t('views.service_billings.form.no_suggestion')} </span>
        </div>`;
      this.suggestionsTarget.parentElement.classList.add('open');
    }
  }

  selectSuggestion(event) {
    const suggestion = event.target.textContent.trim();
    this.titleInputTarget.value = suggestion;
    this.clearSuggestions();
    this.trackSelection();
  }

  clearSuggestions() {
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.parentElement.classList.remove('open');
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.clearSuggestions();
    }
  }

  trackSelection() {
    trackEvent('PropertyFine',
      { action: I18n.t('views.property_fine.mix_panel_suggestions'), community_id: this.element.dataset.communityIdValue } );
  }
}

