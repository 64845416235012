import { Controller } from 'stimulus'

const statusClasses = {
  'cancelled': 'text-danger-modal',
  'failed': 'text-danger-modal',
  'imported': 'text-success-modal',
  'in_process': 'text-alert-modal',
  'pending': 'text-alert-modal',
};

export default class extends Controller {
  static targets = [
    'spinner', 'modal',
    'modalTitle', 'modalButtons',
    'quantity', 'quantityTitle',
    'dateTitle', 'date',
    'userTitle', 'user',
    'fileLink', 'fileSpan',
    'status'
  ]

  async open(event) {
    event.preventDefault();

    this.showSpinner();
    const details = await this.getDetails(event.currentTarget.id);
    if (details === undefined) return;
    this.showModal();

    this.showQuantityData(details);
    this.showStatus(details);
    this.showDate(details);
    this.showUser(details);
    this.showFile(details);
  }

  async getDetails(url) {
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    };
    let response;
    try {
      const res = await fetch(url, {
        method: 'GET',
        headers: headers,
      })
      response = await res.json()
    } catch (error) {
      console.error('Error fetching details:', error)
      return
    }
    return response
  }

  showSpinner() {
    this.modalTarget.classList.add('hidden');
    this.modalTitleTarget.classList.add('hidden');
    this.modalButtonsTarget.classList.add('hidden');
    this.spinnerTarget.classList.remove('hidden');
  }

  showModal() {
    this.modalTarget.classList.remove('hidden');
    this.modalTitleTarget.classList.remove('hidden');
    this.modalButtonsTarget.classList.remove('hidden');
    this.spinnerTarget.classList.add('hidden');
  }

  showQuantityData(details) {
    this.quantityTitleTarget.innerHTML  = details.quantity.title;
    this.quantityTarget.innerHTML  = details.quantity.content;
  }

  showStatus(details) {
    this.statusTarget.innerHTML  = details.status.content;
    this.statusTarget.classList.remove('text-success-modal', 'text-danger-modal', 'text-alert-modal');
    this.statusTarget.classList.add(statusClasses[details.status.code])
  }

  showDate(details) {
    this.dateTitleTarget.innerHTML  = details.date.title;
    this.dateTarget.innerHTML  = details.date.content;
  }

  showUser(details) {
    this.userTitleTarget.innerHTML = details.user.title;
    this.userTarget.innerHTML = details.user.content;
  }

  showFile(details) {
    this.fileSpanTarget;
    this.fileLinkTarget;
    this.fileLinkTarget.href = details.file.path;

    if (details.file.name != undefined) {
      this.fileSpanTarget.setAttribute("title", details.file.name);
      this.fileSpanTarget.dataset.toggle = "tooltip";
      this.fileSpanTarget.dataset.placement = "right";
      this.fileSpanTarget.dataset.trigger = "hover";

      this.fileLinkTarget.innerHTML = details.file.name;
    } else {
      this.fileLinkTarget.innerHTML = details.file.path;
    }
  }
}
