import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["link"];

  disable() {
    this.linkTarget.classList.add("disabled");

    setTimeout(() => {
      this.linkTarget.classList.remove("disabled");
    }, 5000);
  }
}
