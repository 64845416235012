import React, { useState } from "react";
import { Redirect } from 'react-router-dom'
import I18n from "i18n-js";
import { useTheme } from "@material-ui/core/styles";
import RouterLink from "../../components/RouterLink";
import { ButtonBase, Typography } from "@material-ui/core/";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {GLOBAL_STATE} from "../../state";
import useStyles from './RegisterPartOwner.styles';

function StepPreviousActions({ previous, next, numberStepLabel, numberStep }) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [back, setBack] = useState(false);
    const [logoutSession, setLogout] = useState(false);
    const { newPropertyRequest, res2_redirection } = GLOBAL_STATE;

    let response;

    function goBack() {
        setBack(true);
    }

    function log_out(){
        setLogout(true)
    }

    let do_logout = () => {
        window.location.href = "/log_out";
    };

    let do_home = () => {
        window.location.href = res2_redirection ?  "/resident_redirect?propertyRegistry=true" : "/home";
    };

    let redirect_login = () => {
        window.location.href = "/formulario";
    };

    if (numberStep === 1) {
        response = (
            <ButtonBase focusRipple={false} onClick={goBack} className={classes.chevronIcon}>
                <ChevronLeftIcon onClick={goBack} />
                <Typography variant="body1">
                    <RouterLink onClick={redirect_login} to="/home" label={matches ? "" : I18n.t('modules.register.btn_cancel')} />
                </Typography>
            </ButtonBase>
        )
    } else {
        response =
          (<ButtonBase focusRipple={false} onClick={previous ? previous : log_out }>
            <ChevronLeftIcon
              onClick={previous ? previous : log_out }
              className={classes.chevronIcon}
            />
            <Typography variant="body1" className={classes.backLink}>
              <label className={classes.chevronIcon}>
                {matches ? "" : I18n.t("modules.register.btn_cancel")}
              </label>
            </Typography>
          </ButtonBase>
        )

    }
    if(newPropertyRequest) {
        return (<ButtonBase focusRipple={false} onClick={previous ? previous : do_home }>
                        <ChevronLeftIcon
                            onClick={previous ? previous : do_home }
                            className={classes.chevronIcon}
                        />
                        <Typography variant="body1" className={classes.backLink}>
                            <label className={classes.chevronIcon}>
                                {matches ? "" : I18n.t("modules.register.btn_cancel")}
                            </label>
                        </Typography>
                    </ButtonBase>);
    }else{
        return back ? <Redirect to="/log_in" /> : logoutSession ? do_logout() : response;
    }
}

export default StepPreviousActions
