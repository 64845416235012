import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["selectAllowOnlinePayment", "deactivationReasonWrapper","deactivationReasonTextWrapper","selectOnlinePaymentDeactivationReason", "deactivationReasonText"] 

  connect() {
    this.allowDeactivationReason()
    this.allowDeactivationReasonText()
  }

  allowDeactivationReason() {
    if(this.selectAllowOnlinePaymentTarget.value === '1' || this.selectAllowOnlinePaymentTarget.dataset.initialPaymentSetting === "0") {
      this.deactivationReasonWrapperTarget.classList.add('hidden')
      this.selectOnlinePaymentDeactivationReasonTarget.removeAttribute('required')
      this.hideDeactivationReasonText()
      if(this.selectAllowOnlinePaymentTarget.dataset.initialPaymentSetting === "1"){
        this.selectOnlinePaymentDeactivationReasonTarget.selectedIndex = 0
        this.deactivationReasonTextTarget.value = ''
      }
    } else {
      this.deactivationReasonWrapperTarget.classList.remove('hidden')
      this.selectOnlinePaymentDeactivationReasonTarget.setAttribute('required', 'required')
    }
  }


  allowDeactivationReasonText() {
    if((this.selectOnlinePaymentDeactivationReasonTarget.value === 'other' && this.selectAllowOnlinePaymentTarget.value != '1') && this.selectAllowOnlinePaymentTarget.dataset.initialPaymentSetting === "1") {
      this.deactivationReasonTextWrapperTarget.classList.remove('hidden')
      this.deactivationReasonTextTarget.setAttribute('required', 'required')
      this.deactivationReasonTextTarget.setAttribute('minlength', '20')
    } else {
      this.hideDeactivationReasonText()
    }
  }

  hideDeactivationReasonText(){
    this.deactivationReasonTextWrapperTarget.classList.add('hidden')
    this.deactivationReasonTextTarget.removeAttribute('required')
    this.deactivationReasonTextTarget.removeAttribute('minlength')
    this.deactivationReasonTextTarget.setCustomValidity('')
  }

  inputPaymentDeactivationReasonText(event){
    const value = this.deactivationReasonTextTarget.value
    const hasContinuousSpaces = /\s{2,}/.test(value)
    const emptyValidationText = this.deactivationReasonTextTarget.dataset.emptyValidationText 

    if (hasContinuousSpaces) {
      this.deactivationReasonTextTarget.setCustomValidity(emptyValidationText)
    } else {
      this.deactivationReasonTextTarget.setCustomValidity('')
    }
  }
}

