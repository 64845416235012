import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'maxlengthMessage']
  static values = { maxlength: Number }

  connect() {
    this.checkLength = this._debounce(this.checkLength.bind(this), 100)
  }

  checkLength() {
    if (!this.hasMaxlengthValue) return

    const length = this.inputTarget.value.length
    const isMaxExceeded = length >= this.maxlengthValue

    this.maxlengthMessageTarget.classList.toggle('hidden', !isMaxExceeded);
    this.maxlengthMessageTarget.classList.toggle('text-danger', isMaxExceeded)
    this.inputTarget.classList.toggle('warning-input', isMaxExceeded)
  }

  _debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }
}
