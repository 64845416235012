import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'paymentMethods', 'submitButton', 'ppdRadioButton', 'pueRadioButton'];

  handlePPD() {
    this.disablePaymentMethods();
    this.enableSubmitButton();
    this.ppdRadioButtonTarget.checked = true;
    this.pueRadioButtonTarget.checked = false;
  }

  handlePUE() {
    this.enablePaymentMethods();
    this.checkSubmitButton();
    this.ppdRadioButtonTarget.checked = false;
    this.pueRadioButtonTarget.checked = true;
  }

  handleSelect(){
    this.checkSubmitButton();
  }

  enablePaymentMethods() {
    this.paymentMethodsTarget.classList.remove('hidden');
  }

  disablePaymentMethods() {
    this.paymentMethodsTarget.classList.add('hidden');
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled');
    this.submitButtonTarget.classList.add('green');
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add('disabled');
    this.submitButtonTarget.classList.remove('green');
  }

  checkSubmitButton() {
    const formInput = document.querySelector('[data-cf-selector-target="formInput"]');
    if (formInput.value !== '') {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  submit() {
    this.formTarget.submit();
  }
}
