import { Controller } from 'stimulus';
import I18n from 'i18n-js';

export default class extends Controller {
  static values = { email: String };
  static targets = ['copyMessage']

  async copy(event) {
    event.preventDefault();

    try {
      await navigator.clipboard.writeText(this.emailValue);
      this.showCopyMessage();
    } catch (error) {
      triggerAlert(I18n.t('views.property_users.email_dropdown.copy_error'));
      allow_multiple_alerts();
    }
  }

  showCopyMessage() {
    this.copyMessageTarget.classList.remove('hidden');

    setTimeout(() => {
      this.copyMessageTarget.classList.add('hidden');
    }, 2000);
  }
}
