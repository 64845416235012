import { Controller } from 'stimulus'
import I18n from 'i18n-js'


export default class extends Controller {
  static values = {
    openedViolationByProperty: Object,
    indexUrl: String
  }
  static targets = ['deadlineSelector', 'issueDateSelector', 'title', 'submitButton', 'property', 'body', 'modalTitle', 'modalBody', 'closeButton', 'confirmationModal']

  issueDateSelectorTargetConnected() {
    this.issueDateSelectorTarget.addEventListener('change', () => this.updateDeadline())
    this.issueDateSelectorTarget.addEventListener('change', () => this.verifySubmitButton())
  }

  deadlineSelectorTargetConnected() {
    this.deadlineSelectorTarget.addEventListener('change', () => this.verifySubmitButton())
  }

  updateDeadline() {
    let issueDate = this.issueDateSelectorTarget.value
    if (issueDate != '') {
      let deadlineMinDate = this.deadlineSelectorTarget.parentElement.querySelector('#start-date-range')
      let dates = [issueDate, deadlineMinDate.value]
      let minDate = dates.reduce((acc, date) => {return acc && new Date(acc) > new Date(date) ? acc : date }, '')
      deadlineMinDate.value = issueDate
      if (new Date(issueDate) > new Date(this.deadlineSelectorTarget.value)) {
        this.deadlineSelectorTarget.value = minDate
      }
      this.deadlineSelectorTarget.parentElement.querySelector('#violation_deadline-selector-input').value = this.formatDateTime(minDate, 'default_slash')
      this.deadlineSelectorTarget.dispatchEvent(new Event('change'))
    }
  }

  verifySubmitButton() {
    let missingTitle = this.titleTarget.value.length == 0
    let missingProperty = false
    if (this.hasPropertyTarget) {
      missingProperty = this.propertyTarget.value == ''
    }
    let missingBody = tinymce?.activeEditor?.getContent({ format: 'text' }) == '' || tinymce?.activeEditor?.getContent({ format: 'text' }) == "\n"
    this.submitButtonTarget.disabled = missingTitle || missingProperty || missingBody
  }

  verifyProperty() {
    if (this.openedViolationByPropertyValue[this.propertyTarget.value]){
      let property = this.propertyTarget.value;
      let baseUrl = this.indexUrlValue;
      let filteredUrl = property ? `${baseUrl}&property[]=${property}` : baseUrl;

      this.modalTitleTarget.textContent = I18n.t('views.violations.new.reminder_modal_title', { property: this.openedViolationByPropertyValue[property] })
      this.modalBodyTarget.innerHTML = I18n.t('views.violations.new.reminder_modal_body', { url: filteredUrl });
      this.closeButtonTarget.textContent = I18n.t('common.cancel')
    } else {
      this.modalTitleTarget.textContent = I18n.t('views.violations.new.confirmation_modal_title')
      this.modalBodyTarget.textContent = I18n.t('views.violations.new.confirmation_modal_body')
      this.closeButtonTarget.textContent = I18n.t('common.close')
    }
  }

  openConfirmationModal(event) {
    event.preventDefault();
    $(this.confirmationModalTarget).modal('show')
  }

  closeConfirmationModal(event) {
    event.preventDefault();
    $(this.confirmationModalTarget).modal('hide')
  }

  disableConfirmButton(event) {
    this.submitButtonTarget.disabled = true;
  }
  
  formatDateTime(datetime, format) {
    let date = this.splitDate(datetime)
    const year = date.year
    const month = String(date.month).padStart(2, '0')
    const day = String(date.day).padStart(2, '0')
    const string = I18n.t(`datetime.formats.${format}`)
    return string.replace('%m', month).replace('%d', day).replace('%Y', year)
  }

  splitDate(date) {
    let data = typeof(date) == 'string' ? date : this.toLocalISOString(date)

    const array = data.split('-')
    return { year: parseInt(array[0]), month: parseInt(array[1]), day: parseInt(array[2]) }
  }

  toLocalISOString(date) {
    if (typeof date === 'string') {
      date = new Date(date)
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
  }
}
