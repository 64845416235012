import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['iframe', 'modal', 'useButton'];
  static values = { url: String }

  open(event) {
    event.preventDefault()

    this.iframeTarget.src = `${event.currentTarget.dataset.templateUrl}#toolbar=0&zoom=0`
    this.useButtonTarget.href = `${this.urlValue}?template_id=${event.currentTarget.dataset.templateId}`
    $(this.modalTarget).modal()
  }
}
