import { Controller } from 'stimulus';
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = ['table', 'pagination', 'previous', 'next']
  static values = { totalPages: Number, currentPage: Number, filters: Object, payments: Array }

  connect() {
    this.buildPaginator(this.currentPageValue)
    this.populateTable(this.paymentsValue)
  }

  buildPaginator(currentPage) {
    const totalPages = this.totalPagesValue;
    const maxVisiblePages = 5;
    let startPage = 1;
    let endPage = maxVisiblePages;

    if (currentPage > maxVisiblePages - 2) {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - maxVisiblePages + 1;
    }

    if (startPage < 1) {
      startPage = 1;
    }

    const paginationItems = [];

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(i);
    }

    const previousButton = this.previousTarget.outerHTML;
    const nextButton = this.nextTarget.outerHTML;
    this.paginationTarget.innerHTML = '';

    this.paginationTarget.insertAdjacentHTML('beforeend', previousButton);

    if (startPage > 1) {
      this.createListItem('1', 'click->traceability-table#changePage')
      if (startPage > 2) {
        this.createDisabledListItem('...')
      }
    }

    paginationItems.forEach(page => {
      this.createListItem(page, 'click->traceability-table#changePage', page === currentPage ? 'active' : '')
    });

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        this.createDisabledListItem('...');
      }
      this.createListItem(totalPages, 'click->traceability-table#changePage');
    }
    this.paginationTarget.insertAdjacentHTML('beforeend', nextButton);

    if (this.hasPreviousTarget) {
      this.previousTarget.classList.toggle('disabled', currentPage === 1);
    }
    if (this.hasNextTarget) {
      this.nextTarget.classList.toggle('disabled', currentPage === totalPages);
    }
  }

  createListItem(content, action, className = '') {
    this.paginationTarget.insertAdjacentHTML('beforeend',
      `<li class="${className}"><a href="#" data-action="${action}">${content}</a></li>`
    )
  }

  createDisabledListItem(content) {
    this.paginationTarget.insertAdjacentHTML('beforeend',
      `<li class="disabled"><span>${content}</span></li>`
    )
  }

  fetchAndPaginateData(page) {
    this.fetchTraceabilityDetailsData(page);
    this.buildPaginator(page);
  }

  setStatus({ params, target }) {
    this.newStatus = params.status;
    this.statusLabelTarget.innerText = target.innerHTML;
  }

  changePage(event) {
    event.preventDefault();
    let page = parseInt(event.target.innerHTML);
    this.fetchAndPaginateData(page)
  }

  nextPage(event) {
    event.preventDefault();
    let currentPage = parseInt(this.paginationTarget.querySelector('.active').children[0].innerHTML);

    if (currentPage < this.totalPagesValue) { this.fetchAndPaginateData(currentPage + 1); }
  }

  previousPage(event) {
    event.preventDefault();

    let currentPage = parseInt(this.paginationTarget.querySelector('.active').children[0].innerHTML);

    if (currentPage > 1) { this.fetchAndPaginateData(currentPage - 1); }
  }

  populateTable(payments) {
    this.parseResponse(payments)
  }

  buildPaymentRow(includeTable = false) {
    const row = document.createElement('tr')
    let content = ''
    if (includeTable) {
      row.classList.add('hide')
      content = `
        <td class="td-payment-container" colspan="9">
          <table class="table-root td-payment-table__table">
            <thead>
              <tr>
                <th>${I18n.t('views.payment_dispersions.payments_table.bank_dispersion_date')}</th>
                <th>${I18n.t('views.payment_dispersions.payments_table.unit')}</th>
                <th>${I18n.t('views.payment_dispersions.payments_table.user')}</th>
                <th>${I18n.t('views.payment_dispersions.payments_table.paid_at')}</th>
                <th>${I18n.t('views.payment_dispersions.payments_table.status')}</th>
                <th class="right">${I18n.t('views.payment_dispersions.payments_table.amount')}</th>
                <th class="right">${I18n.t('views.payment_dispersions.payments_table.folio')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="right"></td>
                <td class="right"></td>
              </tr>
            </tbody>
          </table>
        </td>
      `
    } else {
      content = `
        <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         <td class="payment-amount-cell"></td>
         <td class="folio-th"></td>
         <td></td>
      `
    }
    row.innerHTML = content

    return row
  }

  fetchTraceabilityDetailsData(page) {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('page', page);
    currentUrl.searchParams.set('start_date', this.filtersValue.start_date);
    currentUrl.searchParams.set('end_date', this.filtersValue.end_date);

    fetch(`/payment_dispersions/payments?${currentUrl.searchParams.toString()}`, {
      method: 'GET',
      headers: { 'contentType': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    })
      .then(response => response.json())
      .then(data => {
        this.parseResponse(data.values)
      })
  }

  parseResponse(data) {
    const tbody = this.tableTarget;

    tbody.innerHTML = ''
    data.forEach(payment => {
      const rows = this.buildRow(payment)
      rows.forEach(row => {
        tbody.appendChild(row)
      })
    })
  }

  buildRow(payment) {
    let rows = []
    if (payment.retention > 0) {
      const retentionRow = this.generateRetentionRow(payment)
      rows.push(retentionRow)
    }
    const row = this.generatePaymentRow(payment, rows.length > 0)
    rows.push(row)

    return rows;
  }

  generateRetentionRow(payment) {
    const statusField = this.buildStatusField('retention')
    const showBtn = this.buildShowBtn()
    const values = [payment.return_date, payment.bank_date, null, null, null, statusField, payment.retention_formatted, null, showBtn]

    return this.generateRowWithValues(values)
  }

  generatePaymentRow(payment, hasRetention) {
    const statusField = this.buildStatusField(payment.dispersion_status);

    if (!hasRetention) {
      const values = [payment.return_date, payment.bank_date, payment.property_name, payment.user_name, payment.paid_at, statusField, payment.total_amount, payment.folio, null]
      return this.generateRowWithValues(values)
    }
    const values = [payment.bank_date, payment.property_name, payment.user_name, payment.paid_at, statusField, payment.total_amount, payment.folio]

    return this.generateRowWithValues(values, true)
  }

  generateRowWithValues(values, includeTable = false) {
    const row = this.buildPaymentRow(includeTable)
    const columns = includeTable ? row.querySelector('tbody tr') : row
    this.addContentToRow(values, columns.children)

    return row
  }

  addContentToRow(values, columns) {
    for (let index = 0; index < values.length; index++) {
      const field = values[index];
      if (field === null) {
        columns[index].textContent = '';
      } else if (typeof field === 'string' && field.startsWith('<')) {
        columns[index].innerHTML = field;
      } else {
        columns[index].textContent = field;
      }
    }
  }

  buildStatusField(status) {
    const textLabel = I18n.t(`views.payment_dispersions.payments_table.statuses.${status}`)

    return `<div class="status-label ${status}">${textLabel}</div>`
  }

  buildShowBtn() {
    return `<button class="btn btn-xs btn-default" data-action="click->traceability-table#showPayment">
                <span class="fa fa-eye"></span>
            </button>`
  }

  showPayment(event) {
    const button = event.target
    const parentRow = button.closest('tr')
    const nextRow = parentRow.nextElementSibling

    nextRow.classList.toggle('hide')
    nextRow.classList.toggle('selected-row')
    parentRow.classList.toggle('selected-row')
  }
};
