import { Controller } from 'stimulus'

export default class extends Controller {
  fetchStatus(event) {
    let dataSet = event.currentTarget.dataset
    let frameId = `status-outgoing-mail-${dataSet.id}`
    let frame = document.getElementById(frameId)
    var icon = event.currentTarget.querySelector('.fa-regular')
    icon.classList.remove('fa-regular', 'fa-eye')
    icon.classList.add('fa-solid', 'fa-spinner', 'fa-spin-pulse')
    const onFrameLoad = () => {
      icon.classList.remove('fa-solid', 'fa-spinner', 'fa-spin-pulse')
      icon.classList.add('fa-regular', 'fa-eye-slash')
      icon.closest('.cf-button').setAttribute('data-action', 'click->outgoing-mails--index#hideFrame')
      frame.removeEventListener('turbo:frame-load', onFrameLoad)
    }

    frame.addEventListener('turbo:frame-load', onFrameLoad)
    Turbo.visit(dataSet.url, { turbo: true, acceptsStreamResponse: true, frame: frameId })
  }

  hideFrame(event) {
    let dataSet = event.currentTarget.dataset
    let frameId = `status-outgoing-mail-${dataSet.id}`
    let frame = document.getElementById(frameId)
    var icon = event.currentTarget.querySelector('.fa-regular')
    icon.classList.remove('fa-eye-slash')
    icon.classList.add('fa-eye')
    icon.closest('.cf-button').setAttribute('data-action', 'click->outgoing-mails--index#fetchStatus')
    frame.innerHTML = ''
  }
}
