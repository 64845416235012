import { Controller } from "stimulus"
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ['form', 'button', 'asset', 'title', 'content', 'recipients'];
  static values = {
    submitCount: 0
  }

  initialize() {
    this.addLocale();
    this.prepareChangeEventHandlers();
    this.prepareMultiselect();

    let remoteAssets = document.querySelector('.remote_assets');

    new MutationObserver(() => {
      let inputFile = remoteAssets.querySelector('input[type=file]');
      if (typeof inputFile != 'undefined' && inputFile.files.length == 0) {
        this.assetTarget.querySelector('.form-control').innerHTML = null;
        this.assetTarget.querySelector('.btn-file .spinner').style.display = 'none';
      }
    })
      .observe(remoteAssets, { attributes: true, childList: true, subtree: true });
  }

  addLocale() {
    const community_language = document.querySelector('#community_language').value;
    if (community_language) {
      I18n.locale = community_language;
    }
  }

  prepareMultiselect() {
    $('.multiselect').multiselect({
      includeSelectAllOption: true,
      filterBehavior: 'text',
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      maxHeight: 300,
      selectAllNumber: false,
      disableIfEmpty: true,
      disabledText: I18n.t('views.posts.form.multiselect.disabled_text'),
      includeFilterClearBtn: true,
      dropRight: true,
      includeSelectAllOption: false,
      placeholder: `${I18n.t('views.posts.form.multiselect.placeholder')} ${I18n.t('activerecord.models.property_user.one').toLowerCase()}`,
      nonSelectedText: I18n.t('views.posts.form.multiselect.non_selected_text'),
      nSelectedText: I18n.t('views.posts.form.multiselect.n_selected_text'),
      filterPlaceholder: `${I18n.t('views.posts.form.multiselect.filter_placeholder')} ${I18n.t('activerecord.models.property_user.one').toLowerCase()}`,
      allSelectedText: I18n.t('views.posts.form.multiselect.all_selected_text'),
      numberDisplayed: 1
    });
  }

  prepareChangeEventHandlers() {
    $('.send_community input[type="checkbox"]').change(function() {
      $(this).closest('.row').next('.row').toggleClass('low_opacity_container')
    });

    $('.show-conference').change(function() {
      if(this.checked) {
        $('.conference').show();
        $('.conference input').prop('disabled', false);
      }
      else{
        $('.conference').hide();
        $('.conference input').prop('disabled', true);
      }
    });
  }

  changeTemplate(event) {
    if (window.location.search.includes('template_id')) {
      event.preventDefault();
      $.notify({
        message: `Al cambiar de plantilla se perderán todos los cambios hechos actualmente. ¿Quieres continuar?`,
        icon: 'fa fa-warning',
        url: event.target.dataset.url
      }, {
        type: 'warning',
        template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<center class="text-center mx-0 mt-5"><a class="btn btn-xs btn-default text-center" href="{3}">Aceptar</a></center>' +
        '</div>',
        delay: 5000,
        mouse_over: 'pause'
      });
    }
  }

  submit(event) {
    let newPost = document.getElementById('new_post_two').value
    let clicked = !event.currentTarget.classList.contains('clicked');
    let body = tinymce?.activeEditor?.getContent({ format: 'text' })?.toLowerCase()
    let file = $('input[type="file"]').val()
    let word = body.match(/adjunto/g) || body.match(/adjunta/g)
    let filesAttached = $('.attach-cont')
    let confirmModalButton = document.getElementById('confirm-modal')

    this.submitCountValue += 1;

    if (this.submitCountValue == 1) {
      if (word != null && word.length > 0 && filesAttached[0] == undefined && (file == '' || file == undefined)) {
        $.notify({
          message: I18n.t('views.posts.index.missing_file_message', { word: word[0] } ),
          icon: 'fa fa-warning',
        }, {
          type: 'warning'
        });

        $('html, body').animate({scrollTop:  $('#scroll-target').offset().top - 100}, 500);
        $('.file-input-target').css('border-color', 'rgb(220, 75, 92)')
        $('.file-required-message').css('display', 'block');
        event.preventDefault();
        return
      }
    }

    if (newPost === 'true' && clicked && !document.getElementsByClassName('submit-post-btn')[0].classList.contains('disabled')) {
      confirmModalButton.addEventListener('click', () => {
        $("#post_modal").modal('hide')
        $('form.main').submit();
      })
      let titleText = document.getElementById('title-message')
      let modalText = document.getElementById('text-message')
      let modalText2 = document.getElementById('text-message2')
      let mainAliquot = document.getElementById('main-aliquot')?.checked
      let postSchedule = document.getElementById('post_schedule')?.checked
      let sendToAll = document.getElementById('send_to_all')?.checked

      if (postSchedule) {
        titleText.innerText = I18n.t('helpers.label.post.are_you_sure_post_schedule')
        modalText.innerHTML = I18n.t('helpers.label.post.publish_at', { date: `<b>${this.buildFormattedDateTime()}</b>` });
        modalText2.innerText = I18n.t('helpers.label.post.can_edit_schedule')
        modalText2.innerText+="\n\n\n"
        confirmModalButton.innerText = I18n.t('common.confirm')
      }
      else if (mainAliquot || sendToAll) {
        modalText.innerText = I18n.t('helpers.label.post.all_community')
        modalText.innerText+="\n\n\n"
      } else {
        modalText.innerText = I18n.t('helpers.label.post.some_users')
        modalText.innerText+="\n\n\n"
      }

      $("#post_modal").modal('show')
    } else {
      if ($('#post_post_template_id').val() != '' && $('#post_title').val() != 'Corte de suministro por morosidad' || newPost === 'false') {
        $('form.main').submit();
      }
    }
  }

  buildFormattedDateTime() {
    let date = document.querySelector('[name="post_publish_date"]').value;
    let time = document.getElementById('post_publish_time').value;
    let publishDateTime = new Date(`${date} ${time}`);
    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    let formattedDateTime = publishDateTime.toLocaleString(I18n.locale, options).replace(',', I18n.locale === 'es' ? ' a las' : ' at')
    formattedDateTime += ' hrs.';
    return formattedDateTime;
  }

  uploadAsset({ target }) {
    document.querySelector('.btn.submit').classList.add('disabled');

    let file = target.files[0];
    this.assetTarget.querySelector('.form-control').innerHTML = file.name;
    if (file.size <= 10 * 1024 * 1024) {
      this.assetTarget.querySelector('.btn-file .spinner').style.display = 'block';
      let formAssets = document.querySelector('.remote_assets_item form');
      formAssets.querySelector('.remote_asset').files = target.files;
      this.assetTarget.querySelector('input[type=file]').files = null;
      $(formAssets).trigger('submit.rails');
      $('.file-input-target').css('border-color', 'rgb(204, 204, 204)');
      $('.file-required-message').css('display', 'none');
    } else {
      triggerSmallAlert(`${I18n.t('messages.errors.assets.file_cannot_be_saved')} ${I18n.t('errors.assets.document_file_size')}`);
      this.assetTarget.querySelector('.form-control').innerHTML = null;
    }
  }
}
