import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    this.downloadFile();
  }

  downloadFile() {
    const url = this.element.dataset.redirectUrl;
    const message = this.element.dataset.notice;

    if (!url) return;

    if (message) {
      this.showNotice(message);
    }

    this.triggerDownload(url);
  }

  showNotice(message) {
    $(".alert.success.js-triggered .flash_notice")[0].innerHTML = message
    $(".alert.success.js-triggered").show()
  }

  triggerDownload(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    const checkDownload = setInterval(() => {
      if (document.cookie.includes("download_complete=true")) {
        clearInterval(checkDownload);
        document.cookie = "download_complete=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location.href = this.element.dataset.returnUrl;
      }
    }, 1000);
  }
}
