import { Controller } from 'stimulus';
import I18n from "i18n-js";

export default class extends Controller {
  static values = {
    tourId: String,
    completedTour: Boolean,
    withMockData: Boolean
  };

  static targets = [
    'firstStep',
    'secondStep',
    'thirdStep',
    'fourthStep',
    'showGraphic',
  ];

  connect() {
    if (!this.completedTourValue) {
      this.showModal();
    }
  }

  showModal() {
    const modalId = '#start_onboarding_modal';
    $(modalId).modal('show');
  }

  startTourClicked() {
    const modalId = '#start_onboarding_modal';
    $(modalId).modal('hide');

    this.startPaymentDispersionsTour();
  }

  loadTour() {
    const tourId = this.tourIdValue;
    const tourSize = 4;
    this.paymentDispersionsTour = tourFactoryLearnBasic(tourId, tourSize);
  }

  startPaymentDispersionsTour() {
    this.loadTour();
    this.prepareTour(this.paymentDispersionsTour);
    this.paymentDispersionsTour.start();
    writeStepLearnBasicTour(this.paymentDispersionsTour, 1);
  }

  nextStep(tour, nextPath) {
    tour.next();
    writeStepLearnBasicTour(tour, nextPath);
  }

  nextFunctionStepOne(tour) {
    this.nextStep(tour, 2);
  }

  nextFunctionStepTwo(tour) {
    this.nextStep(tour, 3);
  }

  showDropdownMenu() {
    this.showGraphicTarget.click();
  }

  nextFunctionStepThree(tour) {
    this.showDropdownMenu();
    this.nextStep(tour, 4);
  }

  previousFunctionStepThree(tour) {
    writeStepLearnBasicTour(tour, 2);
    tour.back()
  }

  previousFunctionStepFour(tour) {
    writeStepLearnBasicTour(tour, 3);
    this.showDropdownMenu();
    tour.back()
  }

  endTour(tour) {
    tour.complete();
    endBasicTour(tour.options.id).then(() => {
      if (this.withMockDataValue) {
        window.location.reload();
      }
    });
  }

  previousFunctionStepFive(tour) {
    writeStepLearnBasicTour(tour, 4);
    this.showDropdownMenu();
    tour.back()
  }

  cancelCallback() {
    this.loadTour();
    this.endTour(this.paymentDispersionsTour);
  }

  prepareTour(tour) {
    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.firstStepTarget, on: 'bottom' },
      text: `
        <p class="tour-title-pd center-text">${I18n.t('views.payment_dispersions.onboarding.tour.step_one.title')}</p>
        <p class="center-text tour-description">${I18n.t('views.payment_dispersions.onboarding.tour.step_one.content')}</p>
      `,
      nextPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      id: 1,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepOne(tour),
      cancelCallback: () => this.cancelCallback()
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.secondStepTarget, on: 'top' },
      text: `
        <p class="tour-title-pd center-text">${I18n.t('views.payment_dispersions.onboarding.tour.step_two.title')}</p>
        <p class="center-text tour-description">${I18n.t('views.payment_dispersions.onboarding.tour.step_two.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      previousText: I18n.t('common.back'),
      id: 2,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepTwo(tour),
      cancelCallback: () => this.cancelCallback()
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.thirdStepTarget, on: 'bottom' },
      text: `
        <p class="tour-title-pd center-text">${I18n.t('views.payment_dispersions.onboarding.tour.step_three.title')}</p>
        <p class="center-text tour-description">${I18n.t('views.payment_dispersions.onboarding.tour.step_three.content')}</p>

      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.next'),
      previousText: I18n.t('common.back'),
      id: 3,
      finalAction: false,
      nextFunction: (tour) => this.nextFunctionStepThree(tour),
      previousFunction: (tour) => this.previousFunctionStepThree(tour),
      cancelCallback: () => this.cancelCallback()
    });

    addSteptoLearnBasicTour({
      tour: tour,
      attachTo: { element: this.fourthStepTarget, on: 'top' },
      text: `
        <p class="tour-title-pd center-text">${I18n.t('views.payment_dispersions.onboarding.tour.step_four.title')}</p>
        <p class="center-text tour-description">${I18n.t('views.payment_dispersions.onboarding.tour.step_four.content')}</p>
      `,
      nextPath: window.location.pathname,
      previousPath: window.location.pathname,
      nextText: I18n.t('common.finish'),
      previousText: I18n.t('common.back'),
      id: 4,
      finalAction: false,
      nextFunction: (tour) => this.endTour(tour),
      previousFunction: (tour) => this.previousFunctionStepFour(tour),
      cancelCallback: () => this.cancelCallback()
    });
  }
}
