import { Controller } from 'stimulus'
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['sortableList']

  connect() {
    this.sortableOptions()
  }

  sortableOptions() {
    new Sortable(this.sortableListTarget, {
            animation: 150,
            onEnd: function(evt) {
              evt.from.querySelectorAll('li').forEach( (li, index) => {
                li.querySelector('.number').innerText = index + 1
              })
            }
        });
  }
}
