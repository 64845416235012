import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showModal(event) {
    const { approvalId } = event.currentTarget.dataset;
    this.approvalId = approvalId;

    if (this.approvalId) {
      $(`#approvals-status-summary-modal-${this.approvalId}`).modal('show');
    } else {
      $(this.modalTarget).modal();
    }
  }

  closeModal() {
    if (this.approvalId) {
      $(`#approvals-status-summary-modal-${this.approvalId}`).modal('hide');
    } else {
      $(this.modalTarget).modal('hide');
    }
  }
}
