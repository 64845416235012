import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'output', 'modal', 'form' ]

  connect() {
  }

  setModalDestroyPath(event) {
    let destroy_path = event.currentTarget.dataset.destroyPath
    this.formTarget.setAttribute('action', destroy_path)
  }

  setModalDestroyPathHref(event) {
    let destroy_path = event.currentTarget.dataset.destroyPath
    this.formTarget.setAttribute('href', destroy_path)
  }

  closeModal() {
    $(this.modalTarget).modal('hide')
  }
}
