import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "submit"];

  showModal() {
    $(this.modalTarget).modal();
  }

  closeModal() {
    $(this.modalTarget).modal("hide");
  }

  triggerRegularAction(e) {
    e.preventDefault();

    this.closeModal();
    this.submitTarget.click();
  }
}
