import { Controller } from 'stimulus'
import I18n from 'i18n-js'


export default class extends Controller {
	static targets = ['surchargeFrequency', 'creationDay', 'modalBody', 'amountInput', 'submitButton', 'localeTarget']

	setNextDate() {
    let periodicity = this.surchargeFrequencyTarget.value
		let scheduledDay = this.creationDayTarget.value
		let nextDate = this.calculateNextDate(periodicity, scheduledDay)
    I18n.locale = this.localeTarget;
		this.modalBodyTarget.innerHTML = I18n.t('views.surcharge_rules.save_confirmation_modal.body', { date: nextDate })
	}

	calculateNextDate(periodicity, scheduledDay){
	  let today = new Date()
    let monthOffset = {
        'monthly': 1,
        'bimonthly': 2,
        'quarterly': 3,
        'biannually': 6,
        'annually': 12
    }

    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
    let referenceDay = Math.min(scheduledDay, lastDayOfMonth)
    let referenceDate = new Date(today.getFullYear(), today.getMonth(), referenceDay)

    let newDate
    
    if (referenceDate <= today) {
        let monthsToAdd = monthOffset[periodicity] || 1
        newDate = new Date(referenceDate)
        newDate.setMonth(newDate.getMonth() + monthsToAdd)
    } else {
        newDate = referenceDate
    }

    let year = newDate.getFullYear()
    let month = String(newDate.getMonth() + 1).padStart(2, "0")
    let day = String(newDate.getDate()).padStart(2, "0")

    const format = I18n.t("date.formats.default_slash")

    return format.replace("%Y", year).replace("%m", month).replace("%d", day)
	}

  activateSubmitButton(){
    let missingAmount = this.amountInputTarget.value.length == 2
    if (missingAmount){
      this.submitButtonTarget.setAttribute('disabled', 'disabled')
    } else {
      this.submitButtonTarget.removeAttribute('disabled')
    }
  }
}
