import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['closeModal', 'modalTitle', 'issuerBody', 'inChargeBody', 'issueDateBody', 'closeDateBody', 'statusBody', 'notifiedBody', 'finesBody', 'acceptButton']

  openCloseModal(event) {
    event.preventDefault();
    let button = event.currentTarget;
    let violationData = JSON.parse(button.dataset.violationData);
    let violationUrl = button.dataset.violationUrl;
    this.modalTitleTarget.textContent = I18n.t('views.violations.close.title', { title: violationData['title'] });
    this.issuerBodyTarget.textContent = violationData['issuer']
    this.inChargeBodyTarget.textContent = violationData['in_charge']
    this.issueDateBodyTarget.textContent = violationData['issue_date']
    this.closeDateBodyTarget.textContent = violationData['close_date']
    this.statusBodyTarget.textContent = violationData['status']
    this.notifiedBodyTarget.textContent = violationData['notified']
    this.finesBodyTarget.textContent = violationData['fines']
    this.acceptButtonTarget.setAttribute('href', violationUrl);
    $(this.closeModalTarget).modal('show')
  }

  closeCloseModal(event) {
    event.preventDefault();
    $(this.closeModalTarget).modal('hide')
  }
}
