import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'submitButton']

  connect() {
    this.toggleSubmitButton()
  }

  validatePrice(event) {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    if (!this.hasPriceTarget) return

    const inputElement = this.priceTarget.querySelector('input[type="text"]')
    if (!inputElement) return

    const priceValue = inputElement.value.replace(/[^0-9.]/g, '').trim()

    this.submitButtonTarget.disabled = priceValue === '' || parseFloat(priceValue) === 0
  }
}
