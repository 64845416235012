import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showModal() {
    $(this.modalTarget).modal();
  }

  hideModal() {
    $(this.modalTarget).modal("hide");
  }
}
