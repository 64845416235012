import { Controller } from 'stimulus'

export default class extends Controller {
  open({ params: { acceptButtonId, actionUrl, elementId } }) {
    const modal = document.querySelector(`#${elementId}`)
    const modalAcceptButton = document.querySelector(`#${acceptButtonId}`)

    $(modal).modal()

    modalAcceptButton.setAttribute('href', actionUrl)
  }

  executeAction(event) {
    const url = event.target.dataset.url;
    const method = event.target.dataset.method;

    fetch(url, {
      method: method,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => {
      if (response.ok) {
        response.json().then(data => {
          window.location.href = data.redirect_url;
        });
      }
    });
  }
}
