import { format } from "highcharts";

export function maskRutJquery() {
  $('.rut-field').each(function() {
    $(this).mask('00.000.000-A', { reverse: true, placeholder: '11.111.111-1' });
  });
}

export function unMaskJquery(element) {
  $(element).unmask();
}

export function validateFormat(cleanedValue, cleanedMask) {
  let validValue = cleanedValue;

  for (let index = 0; index < validValue.length; index++) {
    if (cleanedMask[index] == 'A') continue;
    if (!/\d/.test(validValue[index])) {
      return validValue.slice(0, index);
    }
  }

  return validValue;
}

export function applyFormat(validValue, mask) {
  let formatedValue = '';

  let writeIndex = validValue.length - 1;
  for (let index = mask.length - 1; index >= 0; index--) {
    if (writeIndex < 0) break;
    if (mask[index] == '0' || mask[index] == 'A') {
      formatedValue = validValue[writeIndex] + formatedValue;
      writeIndex--;
    } else {
      formatedValue = mask[index] + formatedValue;
    }
  }
  return formatedValue;
}

export function applyMaskPattern(value, mask) {
  let cleanedValue = value.replace(/[^0-9A-Za-z]/g, '');
  let cleanedMask = mask.replace(/[^0-9A-Za-z]/g, '');

  let endMask = cleanedMask.length;
  let startMask = endMask - cleanedValue.length;
  if (startMask < 0) startMask = 0;
  let cleanedMaskToUse = cleanedMask.slice(startMask, endMask);

  let cleanedValueToUse = cleanedValue.slice(0, cleanedMaskToUse.length);

  let validValue = validateFormat(cleanedValueToUse, cleanedMaskToUse);
  return applyFormat(validValue, mask);
}
