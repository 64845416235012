import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'selectorData'];

  connect() {
    this.toggleSelection();
  }

  selectAll(event) {
    const checked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
    this.toggleSelection();
  }

  selectOne() {
    this.toggleSelection();
  }

  toggleSelection() {
    let selectedIds = '';
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedIds += (selectedIds ? ',' : '') + checkbox.value;
      }
    });
    this.selectorDataTarget.value = selectedIds;
  }
}
